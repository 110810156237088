import defaultsDeep from 'lodash/defaultsDeep';

import dayjs from 'Utils/dayjsUtil';
import { getBaseUrl } from 'Utils/urlUtils';

import {
	ACTIVE_LANGUAGE_CODES,
	COOKIE,
	LANGS_WITH_SPECIAL_PERCENTAGE_FORMATTING,
	MICROSOFT_LCID_MAP,
	SEO_NON_INDEXABLE_LANGUAGE_CODES,
} from 'Constants/constants';

import { localizedMetaDataLabels } from 'Static/json/localisedMetaData';
import { EN } from 'Static/labels/english';

import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/nl';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/ar';
import 'dayjs/locale/pl';
import 'dayjs/locale/id';
import 'dayjs/locale/ru';
import 'dayjs/locale/da';
import 'dayjs/locale/ro';
import 'dayjs/locale/tr';
import 'dayjs/locale/sv';
import 'dayjs/locale/nb';

export const initGlobalLocale = ({
	lang,
	cookies,
}: {
	lang: string;
	cookies: Record<string, any>;
}) => {
	let localeCode = lang || cookies[COOKIE.CONTENT_LANG];
	if (!localeCode) {
		return;
	}
	// dayJS does not recognize zh-hans & zh-hant as locale codes
	if (localeCode === 'zh-hans') {
		localeCode = 'zh-cn';
	} else if (localeCode === 'zh-hant') {
		localeCode = 'zh-tw';
	}
	// Set language from dayJS
	dayjs.locale(localeCode);
};

const langExportNameMap = {
	en: 'EN',
	fr: 'FR',
	it: 'IT',
	es: 'ES',
	de: 'DE',
	pt: 'PT',
	nl: 'NL',
	ja: 'JA',
	ko: 'KO',
	'zh-hans': 'ZH_HANS',
	'zh-hant': 'ZH_HANT',
	ar: 'AR',
	pl: 'PL',
	id: 'ID',
	ru: 'RU',
	da: 'DA',
	no: 'NO',
	ro: 'RO',
	tr: 'TR',
	sv: 'SV',
};

const langStrings = {
	en: EN,
	fr: () => import(`Static/labels/français`),
	it: () => import(`Static/labels/italiano`),
	es: () => import(`Static/labels/español`),
	de: () => import(`Static/labels/deutsch`),
	pt: () => import(`Static/labels/português`),
	nl: () => import(`Static/labels/nederlands`),
	ja: () => import(`Static/labels/japanese`),
	ko: () => import(`Static/labels/korean`),
	'zh-hans': () => import(`Static/labels/simplifiedChinese`),
	'zh-hant': () => import(`Static/labels/traditionalChinese`),
	ar: () => import(`Static/labels/arabic`),
	pl: () => import(`Static/labels/polish`),
	id: () => import(`Static/labels/indonesian`),
	ru: () => import('Static/labels/russian'),
	da: () => import('Static/labels/danish'),
	no: () => import('Static/labels/norwegian'),
	ro: () => import('Static/labels/romanian'),
	tr: () => import('Static/labels/turkish'),
	sv: () => import('Static/labels/swedish'),
};

export const getLocalizationLabels = async ({ lang }: { lang: string }) => {
	if (lang === 'en' || !(lang in langStrings)) {
		return EN;
	}
	// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
	const localeStrings = (await langStrings[lang]())[langExportNameMap[lang]];
	return defaultsDeep(localeStrings, EN);
};

export const getLocalizedMetaTags = (lang: string, labelKey: string) => {
	if (
		!lang ||
		SEO_NON_INDEXABLE_LANGUAGE_CODES.includes(lang) ||
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		!localizedMetaDataLabels[lang]
	)
		lang = 'en';
	return labelKey.split('.').reduce(
		(prev, current) => prev && prev[current],
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		localizedMetaDataLabels[lang],
	);
};

export const getLocalizedSelfCanonicalUrl = (lang: string, path: string) => {
	const langPrefix = lang && lang.toUpperCase() !== 'EN' ? `/${lang}` : ``;
	let pathLang = path;
	if (lang && pathLang.indexOf(lang) >= 0)
		pathLang = pathLang.replace(`/${lang}`, '');
	if (pathLang[pathLang.length - 1] === '/')
		pathLang = pathLang.substring(0, pathLang.length - 1);
	return `${getBaseUrl()}${langPrefix + pathLang}/`;
};

export const getLocalizedPercentage = (value: string, lang: string) => {
	const shouldFormat = LANGS_WITH_SPECIAL_PERCENTAGE_FORMATTING.includes(
		String(lang)?.toLowerCase(),
	);

	return shouldFormat ? `${value}\u00A0%` : `${value}%`;
};

export const getFirstValidLocale = (localeCodesArray: string[] = []) =>
	localeCodesArray.filter(locale =>
		ACTIVE_LANGUAGE_CODES.includes(locale),
	)[0];

export const getMicrosoftLCID = (langCode: string): number | undefined => {
	return MICROSOFT_LCID_MAP[langCode];
};
