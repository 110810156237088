import {
	isDevelopmentEnvironment,
	isProductionEnvironment,
	isServer,
} from './envUtils';

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const log = (...printStatement) => {
	if (isLogEnabled() && isDevelopmentEnvironment()) {
		console.log(...printStatement); // eslint-disable-line no-console
	}
};

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const prodLog = (...printStatement) => {
	console.log(...printStatement); // eslint-disable-line no-console
};

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const warn = (...printStatement) => {
	if (isLogEnabled() && !isProductionEnvironment()) {
		console.warn(...printStatement); // eslint-disable-line no-console
	}
};

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const logWithTime = (...printStatement) => {
	log(...printStatement, new Date().getTime());
};

// @ts-expect-error TS(7019): Rest parameter 'err' implicitly has an 'any[]' typ... Remove this comment to see the full error message
export const error = (...err) => {
	if (
		(isServer() && !isDevelopmentEnvironment()) ||
		isProductionEnvironment()
	) {
		// TODO: log the error in sentry
	} else {
		console.error(...err); // eslint-disable-line no-console
	}
};

export const formatLog = (message: any, meta: any) => {
	return `${message}\n${JSON.stringify(meta, null, 2)}`;
};

export const isLogEnabled = () => {
	return process.env.LOG === 'enable';
};

export const getStackTrace = () => {
	try {
		// Dummy error to get stack trace
		throw new Error('stack trace');
	} catch (error: any) {
		return error.stack;
	}
};
