import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrencyCodesList, getCurrentCountryCode } from 'Utils/stateUtils';
import { getApiBaseUrl } from 'Utils/urlUtils';

import { setGeoLocationCurrency } from 'Actions/currency';
import {
	receivedUserCountry,
	requestUserCountry,
} from 'Actions/userGeoLocation';

export const fetchUserGeoLocation =
	() => async (dispatch: any, getState: any) => {
		const url = `${getApiBaseUrl()}/api/v2/geolocate/city`;

		dispatch(requestUserCountry());
		const currentCountryCode = getCurrentCountryCode(getState());

		try {
			const response = await fetch(url);

			const json = await response.json();

			const country = json.currentCountry?.code || currentCountryCode;
			const currencyCode = json.currentCountry?.currency?.code;

			dispatch(receivedUserCountry(country, currentCountryCode));

			if (getCurrencyCodesList(getState()).includes(currencyCode)) {
				dispatch(setGeoLocationCurrency({ currencyCode }));
			}
		} catch (err) {
			error(err);
			dispatch(
				receivedUserCountry(currentCountryCode, currentCountryCode),
			);
		}
	};
