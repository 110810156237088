export const ActionTypes = {
	CHANGE_CITY: 'CHANGE_CITY',
	CHANGE_PAGE: 'CHANGE_PAGE',
	REQUEST_CITIES: 'REQUEST_CITIES',
	RECEIVE_CITIES: 'RECEIVE_CITIES',
	REQUEST_PRODUCT_LIST: 'REQUEST_PRODUCT_LIST',
	RECEIVE_PRODUCT_LIST: 'RECEIVE_PRODUCT_LIST',
	RECEIVE_RECENT_PRODUCT_LIST: 'RECEIVE_RECENT_PRODUCT_LIST',
	REQUEST_PRODUCT_LIST_BY_CITY: 'REQUEST_PRODUCT_LIST_BY_CITY',
	RECEIVE_PRODUCT_LIST_BY_CITY: 'RECEIVE_PRODUCT_LIST_BY_CITY',
	REQUEST_BANNERS: 'REQUEST_BANNERS',
	RECEIVE_BANNERS: 'RECEIVE_BANNERS',
	SET_BOOKING_DATE: 'SET_BOOKING_DATE',
	SET_BOOKING_TIME: 'SET_BOOKING_TIME',
	SET_BOOKING_SLOT: 'SET_BOOKING_SLOT',
	SET_BOOKING_USER_FIELDS: 'SET_BOOKING_USER_FIELDS',
	SET_BULK_BOOKING_USER_FIELDS: 'SET_BULK_BOOKING_USER_FIELDS',
	SET_BOOKING_TOUR_ID: 'SET_BOOKING_TOUR_ID',
	SET_BOOKING_VARIANT_ID: 'SET_BOOKING_VARIANT_ID',
	SET_AIRPORT_TRANSFERS_BOOKING_QUOTE_ID:
		'SET_AIRPORT_TRANSFERS_BOOKING_QUOTE_ID',
	SET_TOUR_DATE: 'SET_TOUR_DATE',
	SET_TOUR_TIME: 'SET_TOUR_TIME',
	SET_TOUR_SLOT: 'SET_TOUR_SLOT',
	SET_TOUR_PEOPLE: 'SET_TOUR_PEOPLE',
	SET_BOOKING_LANG: 'SET_BOOKING_LANG',
	SET_BOOKING_SEATMAP: 'SET_BOOKING_SEATMAP',
	SET_BOOKING_PEOPLE: 'SET_BOOKING_PEOPLE',
	SET_BOOKING_STAGE: 'SET_BOOKING_STAGE',
	SET_AUTO_APPLIED_COUPON: 'SET_AUTO_APPLIED_COUPON',
	SET_GTM_DATA: 'SET_GTM_DATA',
	SET_BOOKING_ITINERARY_COMMENT: 'SET_BOOKING_ITINERARY_COMMENT',
	REQUEST_SEATING_SVG: 'REQUEST_SEATING_SVG',
	RECEIVE_SEATING_SVG: 'RECEIVE_SEATING_SVG',
	SET_PROMO_CODE: 'SET_PROMO_CODE',
	APPLY_PROMO_CODE: 'APPLY_PROMO_CODE',
	SET_HAS_SHOWN_PROMO_SUCCESS_MODAL: 'SET_HAS_SHOWN_PROMO_SUCCESS_MODAL',
	FETCHING_BREAKUP: 'FETCHING_BREAKUP',
	FETCHED_BREAKUP: 'FETCHED_BREAKUP',
	FETCHED_CART_BREAKUP: 'FETCHED_CART_BREAKUP',
	SET_CARD_HOLDER_NAME: 'SET_CARD_HOLDER_NAME',
	SET_CARD_NUMBER: 'SET_CARD_NUMBER',
	SET_CARD_FIRST_6: 'SET_CARD_FIRST_6',
	SET_CARD_LAST_4: 'SET_CARD_LAST_4',
	SET_CARD_MONTH_EXPIRY: 'SET_CARD_MONTH_EXPIRY',
	SET_CARD_YEAR_EXPIRY: 'SET_CARD_YEAR_EXPIRY',
	SET_CARD_CVV: 'SET_CARD_CVV',
	SET_CARD_ADDRESS: 'SET_CARD_ADDRESS',
	SET_CARD_VALIDITY: 'SET_CARD_VALIDITY',
	SET_CHECKOUT_PAYMENT_FIELD_VALIDITIES:
		'SET_CHECKOUT_PAYMENT_FIELD_VALIDITIES',
	SET_ENCRYPTED_CARD_DETAILS: 'SET_ENCRYPTED_CARD_DETAILS',
	SET_USER_DETAILS: 'SET_USER_DETAILS',
	SET_TOUR_USER_DETAILS: 'SET_TOUR_USER_DETAILS',
	SET_COUNTRY_DIAL_CODE: 'SET_COUNTRY_DIAL_CODE',
	SET_USER_NAME_COMPLETE: 'SET_USER_NAME_COMPLETE',
	MAKING_BOOKING: 'MAKING_BOOKING',
	MADE_BOOKING: 'MADE_BOOKING',
	REQUEST_USER_ACTION: 'REQUEST_USER_ACTION',
	INCREMENT_API_COUNT: 'INCREMENT_API_COUNT',
	DECREMENT_API_COUNT: 'DECREMENT_API_COUNT',
	SET_BOOKING_RESPONSE_DATA: 'SET_BOOKING_RESPONSE_DATA',
	REQUEST_SEARCH_RESULTS: 'REQUEST_SEARCH_RESULTS',
	RECEIVE_SEARCH_RESULTS: 'RECEIVE_SEARCH_RESULTS',
	REQUEST_SEATMAP_DETAILS: 'REQUEST_SEATMAP_DETAILS',
	RECEIVE_SEATMAP_DETAILS: 'RECEIVE_SEATMAP_DETAILS',
	SET_PROFILE_TYPE: 'SET_PROFILE_TYPE',
	UPDATE_USER_ATTRIBUTES: 'UPDATE_USER_ATTRIBUTES',
	REQUEST_SIMILAR_PRODUCTS: 'REQUEST_SIMILAR_PRODUCTS',
	RECEIVE_SIMILAR_PRODUCTS: 'RECEIVE_SIMILAR_PRODUCTS',
	REQUEST_SUB_CATEGORIES: 'REQUEST_SUB_CATEGORIES',
	RECEIVE_SUB_CATEGORIES: 'RECEIVE_SUB_CATEGORIES',
	FETCHING_ORDER_STATUS_CONFIRMATION: 'FETCHING_ORDER_STATUS_CONFIRMATION',
	FETCHED_ORDER_STATUS_CONFIRMATION: 'FETCHED_ORDER_STATUS_CONFIRMATION',
	FETCHED_ORDER_LIST: 'FETCHED_ORDER_LIST',
	SET_SERVER_API_STATUS: 'SET_SERVER_API_STATUS',
	SET_SERVER_PAGE_STATUS: 'SET_SERVER_PAGE_STATUS',
	CHANGE_CURRENCY: 'CHANGE_CURRENCY',
	CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
	CHANGE_LOCALIZED_CONTENT_LANGUAGE: 'CHANGE_LOCALIZED_CONTENT_LANGUAGE',
	CHANGE_LOCATION_CURRENCY: 'CHANGE_LOCATION_CURRENCY',
	REQUEST_CURRENCIES: 'REQUEST_CURRENCIES',
	RECEIVE_CURRENCIES: 'RECEIVE_CURRENCIES',
	REQUEST_LANGUAGES: 'REQUEST_LANGUAGES',
	RECEIVE_LANGUAGES: 'RECEIVE_LANGUAGES',
	SET_BACKEND_EXPERIMENTS: 'SET_BACKEND_EXPERIMENTS',
	SET_PAYMENT_GATEWAY_DETAILS: 'SET_DEFAULT_PAYMENT_GATEWAY_DETAILS',
	DISABLE_PAYMENT_METHOD: 'DISABLE_PAYMENT_METHOD',
	SET_CUSTOM_PAYMENT_FIELD_VALUE: 'SET_CUSTOM_PAYMENT_FIELD_VALUE',
	SET_EXTRA_INFORMATION_FLAG: 'SET_EXTRA_INFORMATION_FLAG',
	SET_THUNK_ERROR: 'SET_THUNK_ERROR',
	CLEAR_THUNK_ERROR: 'CLEAR_THUNK_ERROR',
	SET_UGC_UPLOADS_BY_CATEGORY: 'SET_UGC_UPLOADS_BY_CATEGORY',
	SET_UGC_UPLOADS_BY_TOUR_GROUP: 'SET_UGC_UPLOADS_BY_TOUR_GROUP',
	SET_BOOKING_ID: 'SET_BOOKING_ID',
	SET_BOOK_BUTTON_LOCK: 'SET_BOOK_BUTTON_LOCK',
	SET_PAYMENT_ERROR: 'SET_PAYMENT_ERROR',
	SET_FINGERPRINT_DETAILS: 'SET_FINGERPRINT_DETAILS',
	SET_USER_FIELD_VALUES: 'SET_USER_FIELD_VALUES',
	SET_TOUR_USER_FIELD_VALUES: 'SET_TOUR_USER_FIELD_VALUES',
	SET_ITINERARY_DETAILS: 'SET_ITINERARY_DETAILS',
	REQUEST_USER_GEO_LOCATION: 'REQUEST_USER_GEO_LOCATION',
	RECEIVE_USER_GEO_LOCATION: 'RECEIVE_USER_GEO_LOCATION',
	REQUEST_PRODUCTS: 'REQUEST_PRODUCTS',
	REQUEST_TRANSLATED_PRODUCT_CONTENT: 'REQUEST_TRANSLATED_PRODUCT_CONTENT',
	RECEIVE_PRODUCTS: 'RECEIVE_PRODUCTS',
	REQUEST_PRODUCT: 'REQUEST_PRODUCT_NEW',
	RECEIVE_PRODUCT: 'RECEIVE_PRODUCT_NEW',
	RECEIVE_BULK_PRODUCTS: 'RECEIVE_BULK_PRODUCTS',
	RECEIVE_PRODUCT_CONTENT_TRANSLATED: 'RECEIVE_PRODUCT_CONTENT_TRANSLATED',
	RESET_BOOKING: 'RESET_BOOKING',
	REMOVE_BOOKING: 'REMOVE_BOOKING',
	REMOVE_ALL_RECOMMENDATIONS_BOOKINGS: 'REMOVE_ALL_RECOMMENDATIONS_BOOKINGS',
	RESET_BULK_BOOKING: 'RESET_BULK_BOOKING',
	RESET_PRODUCT: 'RESET_PRODUCT',
	REQUEST_VALIDATED_SEAT_SELECTION: 'REQUEST_VALIDATED_SEAT_SELECTION',
	RECEIVE_VALIDATED_SEAT_SELECTION: 'RECEIVE_VALIDATED_SEAT_SELECTION',
	CLEAR_SEAT_VALIDATION_SELECTION: 'CLEAR_SEAT_VALIDATION_SELECTION',
	DELETE_INVALID_SELECTED_SEATS: 'DELETE_INVALID_SELECTED_SEATS',
	SET_DOMAIN_CONFIG: 'SET_DOMAIN_CONFIG',
	CHANGE_PROFILE_TYPE: 'CHANGE_PROFILE_TYPE',
	REQUEST_AFFILIATE_DETAILS: 'REQUEST_AFFILIATE_DETAILS',
	REQUEST_USER_DETAILS: 'REQUEST_USER_DETAILS',
	RECEIVE_USER_DETAILS: 'RECEIVE_USER_DETAILS',
	DELETE_USER_DETAILS: 'DELETE_USER_DETAILS',
	REQUEST_AFFILIATE_RESET_PASSWORD: 'REQUEST_AFFILIATE_RESET_PASSWORD',
	RECEIVE_AFFILIATE_RESET_PASSWORD_DETAILS:
		'RECEIVE_AFFILIATE_RESET_PASSWORD_DETAILS',
	HANDLE_LOGIN_FALURE: 'HANDLE_LOGIN_FALURE',
	OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
	CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
	RECEIVE_CLOSEBY_CITY_CODES: 'RECEIVE_CLOSEBY_CITY_CODES',
	SET_GEOLOCATION_CURRENCY: 'SET_GEOLOCATION_CURRENCY',
	SET_SANDBOX_ID: 'SET_SANDBOX_ID',
	SET_INSTANT_CHECKOUT_TYPE: 'SET_INSTANT_CHECKOUT_TYPE',
	REQUEST_COLLECTIONS_DATA_BY_ID: 'REQUEST_COLLECTIONS_DATA_BY_ID',
	RECEIVE_COLLECTIONS_DATA_BY_ID: 'RECEIVE_COLLECTIONS_DATA_BY_ID',
	REQUEST_COLLECTIONS_DATA_BY_CITY_CODE:
		'REQUEST_COLLECTIONS_DATA_BY_CITY_CODE',
	RECEIVE_COLLECTIONS_DATA_BY_CITY_CODE:
		'RECEIVE_COLLECTIONS_DATA_BY_CITY_CODE',
	RECEIVE_COLLECTIONS_CARD_DATA_BY_ID: 'RECEIVE_COLLECTIONS_CARD_DATA_BY_ID',
	REQUEST_COLLECTIONS_DATA_BY_CATEGORY_ID:
		'REQUEST_COLLECTIONS_DATA_BY_CATEGORY_ID',
	RECEIVE_COLLECTIONS_DATA_BY_CATEGORY_ID:
		'RECEIVE_COLLECTIONS_DATA_BY_CATEGORY_ID',
	REQUEST_COLLECTIONS_DATA_BY_SUBCATEGORY_ID:
		'REQUEST_COLLECTIONS_DATA_BY_SUBCATEGORY_ID',
	RECEIVE_COLLECTIONS_DATA_BY_SUBCATEGORY_ID:
		'RECEIVE_COLLECTIONS_DATA_BY_SUBCATEGORY_ID',
	REQUEST_CATEGORIES_AND_SUBCATEGORIES:
		'REQUEST_CATEGORIES_AND_SUBCATEGORIES',
	RECEIVE_CATEGORIES_AND_SUBCATEGORIES:
		'RECEIVE_CATEGORIES_AND_SUBCATEGORIES',
	REQUEST_CATEGORY_FEED: 'REQUEST_CATEGORY_FEED',
	RECEIVE_CATEGORY_SECTIONS: 'RECEIVE_CATEGORY_SECTIONS',
	RECEIVE_SUBCATEGORY_FILTERS_BY_CATEGORY:
		'RECEIVE_SUBCATEGORY_FILTERS_BY_CATEGORY',
	REQUEST_NEARBY_CITIES_FROM_LAT_LONG: 'REQUEST_NEARBY_CITIES_FROM_LAT_LONG',
	RECEIVE_NEARBY_CITIES_FROM_LAT_LONG: 'RECEIVE_NEARBY_CITIES_FROM_LAT_LONG',
	SET_SHOW_COPY_DISCOUNT_BANNER: 'SET_SHOW_COPY_DISCOUNT_BANNER',
	REQUEST_CALENDAR_INVENTORIES: 'REQUEST CALENDAR INVENTORIES',
	RECEIVE_CALENDAR_INVENTORIES: 'RECEIVE CALENDAR INVENTORIES',
	RECEIVE_BULK_CALENDAR_INVENTORIES: 'RECEIVE BULK CALENDAR INVENTORIES',
	SET_PROMO_CODE_ERROR: 'SET_PROMO_CODE_ERROR',
	REQUEST_BLOG_POSTS: 'REQUEST_BLOG_POSTS',
	RECEIVE_BLOG_POSTS: 'RECEIVE_BLOG_POSTS',
	REQUEST_RESERVE_SESSION_KEY: 'REQUEST_RESERVE_SESSION_KEY',
	RECEIVE_RESERVE_SESSION_KEY: 'RECEIVE_RESERVE_SESSION_KEY',
	CANCEL_RESERVE_SESSION_KEY: 'CANCEL_RESERVE_SESSION_KEY',
	REQUEST_CHECKOUT_METADATA: 'REQUEST_CHECKOUT_METADATA',
	RECEIVE_CHECKOUT_METADATA: 'RECEIVE_CHECKOUT_METADATA',
	REMOVE_URGENCY_BANNER: 'REMOVE_URGENCY_BANNER',
	BILINK_COUPON_APPLIED: 'BILINK_COUPON_APPLIED',
	SET_VOUCHER_DATA: 'SET_VOUCHER_DATA',
	SET_ACTIVE_MODAL: 'SET_ACTIVE_MODAL',
	SET_OVERRIDE_LAZYLOAD: 'SET_OVERRIDE_LAZYLOAD',
	REQUEST_COMBO_UPSELL_DATA: 'REQUEST_COMBO_UPSELL_DATA',
	RECEIVE_COMBO_UPSELL_DATA: 'RECEIVE_COMBO_UPSELL_DATA',
	REQUEST_TRAVELER_MEDIA: 'REQUEST_TRAVELER_MEDIA',
	RECEIVE_TRAVELER_MEDIA_BY_TGID: 'RECEIVE_TRAVELER_MEDIA_BY_TGID',
	APPEND_TRAVELER_MEDIA_BY_TGID: 'APPEND_TRAVELER_MEDIA_BY_TGID',
	REQUEST_REVIEWS_BY_TGID: 'REQUEST_REVIEWS_BY_TGID',
	RECEIVE_REVIEWS_BY_TGID: 'RECEIVE_REVIEWS_BY_TGID',
	REQUEST_REVIEWS_BY_CITY: 'REQUEST_REVIEWS_BY_CITY',
	RECEIVE_REVIEWS_BY_CITY: 'RECEIVE_REVIEWS_BY_CITY',
	APPEND_REVIEWS_BY_CITY: 'APPEND_REVIEWS_BY_CITY',
	REQUEST_REVIEWS_BY_COLLECTION: 'REQUEST_REVIEWS_BY_COLLECTION',
	RECEIVE_REVIEWS_BY_COLLECTION: 'RECEIVE_REVIEWS_BY_COLLECTION',
	APPEND_REVIEWS_BY_COLLECTION: 'APPEND_REVIEWS_BY_COLLECTION',
	REQUEST_CITIES_LIST: 'REQUEST_CITIES_LIST',
	RECEIVE_CITIES_LIST: 'RECEIVE_CITIES_LIST',
	REQUEST_REVIEWS_BY_PERSONA: 'REQUEST_REVIEWS_BY_PERSONA',
	RECEIVE_REVIEWS_BY_PERSONA: 'RECEIVE_REVIEWS_BY_PERSONA',
	REQUEST_COLLECTIONS_DATA_BY_PERSONA_ID:
		'REQUEST_COLLECTIONS_DATA_BY_PERSONA_ID',
	RECEIVE_COLLECTIONS_DATA_BY_PERSONA_ID:
		'RECEIVE_COLLECTIONS_DATA_BY_PERSONA_ID',
	REQUEST_COLLECTIONS_LIST: 'REQUEST_COLLECTIONS_LIST',
	RECEIVE_COLLECTIONS_LIST: 'RECEIVE_COLLECTIONS_LIST',
	REQUEST_COLLECTIONS_LIST_BY_CITY: 'REQUEST_COLLECTIONS_LIST_BY_CITY',
	RECEIVE_COLLECTIONS_LIST_BY_CITY: 'RECEIVE_COLLECTIONS_LIST_BY_CITY',
	REQUEST_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID:
		'REQUEST_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID',
	RECEIVE_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID:
		'RECEIVE_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID',
	REQUEST_TGID_BILINK_CONFIG: 'REQUEST_TGID_BILINK_CONFIG',
	RECEIVE_TGID_BILINK_CONFIG: 'RECEIVE_TGID_BILINK_CONFIG',
	RECIEVE_USER_FIELDS: 'RECIEVE_USER_FIELDS',
	RECIEVE_BULK_USER_FIELDS: 'RECIEVE_BULK_USER_FIELDS',
	SET_PROMO_DETAILS: 'SET_PROMO_DETAILS',
	SET_VARIANT_FLOW: 'SET_VARIANT_FLOW',
	SET_ADYEN_BROWSER_INFO: 'SET_ADYEN_BROWSER_INFO',

	SET_BOOKING_FILTER_VALUE: 'SET_BOOKING_FILTER_VALUE',
	/* cart */
	REQUEST_CART_RECOMMENDATIONS_DATA: 'REQUEST_CART_RECOMMENDATIONS_DATA',
	RECEIVE_CART_RECOMMENDATIONS_DATA: 'RECEIVE_CART_RECOMMENDATIONS_DATA',
	SET_CART_RECOMMENDATIONS_DATA: 'SET_CART_RECOMMENDATIONS_DATA',
	ADD_CART_SELECTIONS: 'ADD_CART_SELECTIONS',
	TRANSFER_CART_SELECTION_TO_BOOKINGS: 'TRANSFER_CART_SELECTION_TO_BOOKINGS',
	SET_PRODUCT_FEATURE_FLAGS: 'SET_PRODUCT_FEATURE_FLAGS',
} as const;
