import find from 'lodash/find';
import {
	createUserFieldModel,
	customUserFieldModelType,
	customUserFieldType,
} from 'Models/customField';
import { Booking, BookingDetailsPayload } from 'src/types/booking';
import { BreakupResponse } from 'src/types/breakup';
import { Pricing } from 'src/types/pricing';

import { sendVariableToDataLayer, trackPageView } from 'Utils/analytics';
import {
	getFirstAvailableTimeAcrossTours,
	getFirstAvailableTimeInTourDate,
	hasReservedSessionExpired,
	isComboVariant,
	isExternalSeatmapProduct,
	isSeatmap,
} from 'Utils/bookingFlowUtils';
import {
	getBillableCurrencyCode,
	getBillablePrice,
	getPricePayable,
} from 'Utils/breakupUtils';
import { isAffiliate, shouldAllowBooking } from 'Utils/conciergeUtils';
import {
	datetimeStrToTimezone,
	formatDurationToHoursMinutes,
	formatTime,
	formatUsingDayJS,
	getLocalTimezone,
	localDateTimeToDayJSDate,
	localDateTimetoJSDate,
} from 'Utils/dateUtils';
import { formatPrice, isNonEmptyObject, isWhatsAppUser } from 'Utils/gen';
import {
	getAvailableInventory,
	getClientCurrency,
	getInventoriesForTuple,
	getMinPriceTag,
	getTour,
} from 'Utils/pricingUtils';
import { getAppliedPromoCode } from 'Utils/promoUtils';
import { isReservationFlow } from 'Utils/reserveUtils';
import {
	filterSeatMapBySlot,
	getSeatmapBookingDetails,
} from 'Utils/seatmapUtils';
import { read } from 'Utils/sessionStoreUtil';
import { capitalizeFirstLetter } from 'Utils/stringUtils';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	ANALYTICS_FLOW_TYPE,
	AVAILABILITY_TYPE,
	BOOKING_FLOW_TYPE,
	DAY_MONTH_DATE_YEAR_FORMAT,
	DE_TIME_SUFFIX,
	FIELD_ERROR,
	FIELD_LEVEL,
	FIELD_NAME,
	HARRY_POTTER_CURSED_CHILD_TGID,
	INVENTORY_TYPE,
	PAGE_TYPE,
	PAYMENT_METHOD,
	PROFILE_TYPE,
	QUERY_PARAM,
	RESERVATION_SESSION_STORAGE_KEY,
	THREE_DS_REDIRECT_TYPE,
	TOUR_PROPERTIES,
	VALIDITY_TYPES,
} from 'Constants/constants';
import { ICurrency } from 'Constants/currency';
import { strings } from 'Constants/strings';

import { generatePotentialPropertyValuesMap } from './sharedAirportTransfer/stateTransformationUtils';
import { getClarityUrl } from './clarityUtils';
import PlatformUtils from './platformUtils';
import { getSmartLookUrl } from './smartLookUtils';

export const getTotalPassengersFromBooking = (booking: any) => {
	const { selectionMap, groupSize } = booking;
	return getCustomerCount({ selectionMap, groupSize });
};

export const getCustomerCount = ({ selectionMap, groupSize }: any) =>
	selectionMap && Object.keys(selectionMap).length !== 0
		? Object.values(selectionMap).reduce(
				(people, total) => (total as any) + (people as any),
		  )
		: groupSize;

export const getGuestList = ({ priceProfile, selectionMap, groupSize }: any) =>
	selectionMap && selectionMap.length !== 0
		? priceProfile?.persons
				.map(({ type: paxType }: any) =>
					Array.from(
						{ length: selectionMap[paxType] },
						(_x, i) =>
							`${
								strings.CP_PERSON_TYPES[paxType]?.singular ||
								capitalizeFirstLetter(paxType)
							} ${i + 1}`,
					),
				)
				.reduce(
					(guestList: any, paxList: any) => guestList.concat(paxList),
					[],
				)
		: Array.from({ length: groupSize }, (_x, i) =>
				strings.formatString(strings.GUEST_NUMBER, i + 1),
		  );

/**
 * @param name the user field or just the name of the user field as an object
 * @returns {string} the error message for that user field
 */
export const getUserFieldErrorMessage = ({ name }: any) => {
	switch (name) {
		case FIELD_NAME.NAME:
			return FIELD_ERROR.NAME;
		case FIELD_NAME.EMAIL:
			return FIELD_ERROR.EMAIL;
		case FIELD_NAME.PHONE:
			return FIELD_ERROR.PHONE;
		case FIELD_NAME.WEIGHT:
			return FIELD_ERROR.WEIGHT;
		default:
			return 'Please fill all form fields';
	}
};

/**
 * @returns {string} the error message for that user field
 */
export const getUserFieldSubText = ({
	name,
	userCountryCode,
	description,
}: any) => {
	switch (name) {
		case FIELD_NAME.NAME:
			return strings.CPUD_NAME_HELPER_TEXT;
		case FIELD_NAME.EMAIL:
			return strings.CPUD_EMAIL_HELPER_TEXT;
		case FIELD_NAME.PHONE:
			return isWhatsAppUser(userCountryCode)
				? strings.PHONE_HELPER_TEXT.WHATSAPP
				: strings.PHONE_HELPER_TEXT.DEFAULT;
		default:
			return description ?? '';
	}
};

export const getScheduleInfo = (booking: any, pricing: any) => {
	let scheduleInfo = '';

	const { selectedDate, selectedTime, selectedTourId } = booking;

	const tour = getTour(pricing, selectedTourId);
	const inventory = getAvailableInventory(
		{ date: selectedDate, time: selectedTime, tourId: selectedTourId },
		pricing,
	);
	if (!tour || !inventory) {
		return '';
	}

	const { inventoryType } = tour;
	const startTime = formatTime(inventory?.startTime);
	const endTime = formatTime(inventory?.endTime);
	const duration = formatDurationToHoursMinutes(tour?.duration);

	if (inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION) {
		scheduleInfo = strings.formatString(strings.DBPOC_OPEN_UNTIL, endTime);
	} else if (inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FIXED_DURATION) {
		scheduleInfo = `Open between ${startTime} - ${endTime}. Your ticket is for ${duration}.`;
	} else if (
		inventoryType === INVENTORY_TYPE.FLEXIBLE_START_FLEXIBLE_DURATION
	) {
		scheduleInfo = `Open between ${startTime} - ${endTime}`;
	}
	return scheduleInfo;
};

export const getBookingId = (bookingResponse: any, tourId?: any) =>
	tourId
		? bookingResponse.bookings.find(
				(booking: any) => booking.tourId === Number(tourId),
		  )?.id
		: bookingResponse.bookings[0].id;

export const getScheduleInfoList = (pricing: any, date: any) => {
	const { tours } = pricing;
	return tours
		.map((tour: any) => tour?.id)
		.map((tourId: any) => {
			const inventories = getInventoriesForTuple(
				{ date, tourId, time: null },
				pricing,
			);
			const time = inventories ? inventories?.[0]?.startTime : null;
			return {
				selectedTourId: tourId,
				selectedDate: date,
				selectedTime: time,
			};
		})
		.map((booking: any) => getScheduleInfo(booking, pricing));
};

export const getSelectSlotDateTimeString = (
	booking: any,
	pricing: any,
	lang = 'en',
	displayTime = true,
) => {
	const { date, time } = getSelectSlotDateTime(booking, pricing, lang);

	return time && displayTime ? `${date}, ${time}` : `${date}`;
};

export const getSelectSlotDateTime = (
	booking: any,
	pricing: any,
	lang = 'en',
) => {
	const { selectedDate, selectedTime, selectedTourId: tourId } = booking;
	const dateTime = localDateTimeToDayJSDate(selectedDate, selectedTime);

	const date = formatUsingDayJS(dateTime, DAY_MONTH_DATE_YEAR_FORMAT);
	const time = formatUsingDayJS(dateTime, 'LT');
	const tour = getTour(pricing, tourId);
	let isFixedStart;
	if (tour) {
		const { inventoryType } = tour;
		isFixedStart =
			inventoryType === INVENTORY_TYPE.FIXED_START_FIXED_DURATION ||
			inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION;
	}

	if (booking.airportTransfers?.quoteId) {
		isFixedStart = true;
	}

	const trailingTextForDE = lang === 'de' ? DE_TIME_SUFFIX : '';

	return {
		time: isFixedStart ? `${time}${trailingTextForDE}` : null,
		date: date,
		showTime: isFixedStart,
	};
};

export const isFixedStart = (tour: any) => {
	const { inventoryType } = tour;
	return (
		inventoryType === INVENTORY_TYPE.FIXED_START_FIXED_DURATION ||
		inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION
	);
};

/*
	Return an object with keys:
	dateOfMonth, monthName, expandedDate, timeWithoutSeconds
*/
export const getConstructedDateTimeData = (booking: any, pricing: any) => {
	const { id, selectedDate, selectedTime, selectedTourId: tourId } = booking;
	const dateTime = localDateTimetoJSDate(selectedDate, selectedTime);
	const expandedDate = formatUsingDayJS(dateTime, 'ddd, MMM D, YYYY');
	let timeWithoutSeconds = formatUsingDayJS(dateTime, 'LT');
	const monthName = formatUsingDayJS(dateTime, 'mmm');
	const dateOfMonth = formatUsingDayJS(dateTime, 'dd');

	// Handle case of embedded seat maps - if no tour selected then simple return the time.
	if (tourId) {
		const tour = getTour(pricing, tourId);
		const inventoryType = tour?.inventoryType;
		const isFixedStart =
			inventoryType === INVENTORY_TYPE.FIXED_START_FIXED_DURATION ||
			inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION;
		if (!isFixedStart) {
			// Don't show time
			// @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
			timeWithoutSeconds = null;
		}
	}

	if (String(id) === HARRY_POTTER_CURSED_CHILD_TGID) {
		timeWithoutSeconds = '';
	}

	return {
		dateOfMonth,
		monthName,
		expandedDate,
		timeWithoutSeconds,
	};
};

export const getTicketDescription = (booking: any, pricing: any) => {
	const { selectedTourId: tourId } = booking ?? {};
	if (!tourId) return null;
	if (!pricing) return '';

	const tourCount = pricing.tours?.length;
	const { variantName } = getTour(pricing, parseInt(tourId));
	return tourCount > 1 ? variantName : '';
};

export const getPeopleDisplayDescription = (booking: any, pricing: any) => {
	if (!booking) return '';
	const { selectionMap, groupSize } = booking;
	const selections = getPeopleSelections(booking, pricing);

	if (selectionMap && Object.entries(selectionMap).length > 0) {
		return selections
			.map(selection => {
				let personDisplayName = capitalizeFirstLetter(selection.type);
				if (strings.CP_PERSON_TYPES[selection.type]) {
					personDisplayName =
						((selection as any).number > 1
							? strings.CP_PERSON_TYPES[selection.type]?.plural
							: strings.CP_PERSON_TYPES[selection.type]
									?.singular) ||
						capitalizeFirstLetter(selection.type);
				}
				return `${selection.number} ${personDisplayName}`;
			})
			.join(', ');
	} else if (groupSize > 0) {
		return selections
			.map(
				selection =>
					`${selection.number} ${capitalizeFirstLetter(
						selection.type,
					)}`,
			)
			.join(', ');
	}
	return '';
};

/**
 * @param booking
 * @param pricing
 * @returns string 2 Adult, 1 Child or 3 tickets
 */
export const getPeopleDescription = (booking: any, pricing: any) => {
	const selections = getPeopleSelections(booking, pricing);
	const selectionAsStringArray = selections.map(sel => {
		const { number, type } = sel;

		return `${number} ${capitalizeFirstLetter(type)}`;
	});

	return selectionAsStringArray.join(', ');
};

/**
 * @param booking
 * @param pricing
 * returns {Object} {nos: [2, 1], types: ['ADULT', 'CHILD']} or {nos: [3], types: ['Tickets']}
 */
export const getPeopleSelections = (booking: any, pricing: any) => {
	const { selectionMap, groupSize, comboSelections } = booking;
	const selections = [];
	if (
		selectionMap &&
		Object.keys(selectionMap ?? {})?.length > 0 &&
		getPriceProfile(pricing, booking)
	) {
		const { persons } = getPriceProfile(pricing, booking);
		persons.forEach(({ type }: any) => {
			const number = selectionMap[type];
			if ((number as any) > 0) {
				selections.push({ number, type });
			}
		});
	} else if (comboSelections && Object.keys(comboSelections)?.length > 0) {
		Object.entries(comboSelections).forEach(
			([tourId, comboSelectionMap]) => {
				const tourPriceProfile = getTourPriceProfile(
					pricing,
					booking,
					tourId,
				);
				// @ts-expect-error TS(2339): Property 'tourSelectionMap' does not exist on type... Remove this comment to see the full error message
				const { tourSelectionMap, tourGroupSize } = comboSelectionMap;
				if (tourSelectionMap?.length > 0) {
					tourPriceProfile?.persons?.forEach(({ type }: any) => {
						const number = tourSelectionMap[type];
						if ((number as any) > 0) {
							selections.push({ number, type });
						}
					});
				} else if (tourGroupSize > 0) {
					const type = tourGroupSize > 1 ? 'Tickets' : 'Ticket';
					selections.push({ number: tourGroupSize, type });
				}
			},
		);
	} else if (groupSize > 0) {
		const type = groupSize > 1 ? 'Tickets' : 'Ticket';
		selections.push({ number: groupSize, type });
	}

	return selections;
};

/**
 * @param booking
 * @param pricing
 * @param priceProfile
 * @returns {Array} [{number: 2, type: ADULT, price: 80.50},...] or [{number: 3, type: Tickets, price: 200}]
 */
export const getPricesWithSelections = (
	booking: any,
	pricing: any,
	priceProfile: any,
): Array<any> => {
	// @ts-expect-error TS(7034): Variable 'pricesWithSelections' implicitly has typ... Remove this comment to see the full error message
	let pricesWithSelections = [];
	// @ts-expect-error TS(7005): Variable 'pricesWithSelections' implicitly has an ... Remove this comment to see the full error message
	if (!priceProfile) return pricesWithSelections;
	const { priceProfileType, persons, groups } = priceProfile;
	const selections = getPeopleSelections(booking, pricing);

	const getPerson = (personType: any) =>
		persons.find((person: any) => person?.type === personType);

	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		pricesWithSelections = selections.map(selection => {
			const person = getPerson(selection.type);
			const { listingPrice, displayName, retailPrice } = person ?? {};

			return {
				number: selection.number,
				type: selection.type,
				price: listingPrice,
				originalPrice: retailPrice,
				displayName:
					(Number(selection.number) > 1
						? strings.CP_PERSON_TYPES[selection.type]?.plural
						: strings.CP_PERSON_TYPES[selection.type]?.singular) ||
					displayName,
			};
		});
	} else {
		const numPeople = selections[0].number;

		const { listingPrice, retailPrice } =
			getGroupForNumPeople(groups, numPeople) ?? {};

		pricesWithSelections = [
			{
				number: numPeople,
				type: selections[0].type,
				originalPrice: retailPrice,
				price: listingPrice,
			},
		];
	}

	return pricesWithSelections;
};

export const getGroupForNumPeople = (groups: any, numPeople: any) => {
	// reverse-sorting w.r.t. size of group
	const sortedPriceProfile = groups.sort(
		(a: any, b: any) => a?.people - b?.people,
	);

	let groupForNumPeople = sortedPriceProfile[sortedPriceProfile.length - 1];
	for (const group of sortedPriceProfile) {
		if (group?.people >= numPeople) {
			groupForNumPeople = group;
			break;
		}
	}

	return groupForNumPeople;
};

export const getSelectedInventory = (pricing: any, booking: any) => {
	const {
		selectedTourId: tourId,
		selectedDate: date,
		selectedTime: time,
	} = booking;

	return getAvailableInventory({ date, time, tourId }, pricing);
};

export const getPriceProfile = (pricing: any, booking: any) => {
	const inventory = getSelectedInventory(pricing, booking);

	if (inventory) {
		return inventory.priceProfile;
	}

	const { seatmapEmbeddedUrl } = pricing ?? {};
	const { seatMapInfo } = booking ?? {};

	if (seatmapEmbeddedUrl && seatMapInfo) {
		return seatMapInfo.map((info: any) => ({
			priceProfileType: PROFILE_TYPE.PER_PERSON,
			persons: {
				type: 'ADULT',
				listingPrice: info.price,
				ageFrom: 5,
			},
		}));
	}

	return null;
};

export const getTourPriceProfile = (
	pricing: any,
	booking: any,
	tourId: any,
) => {
	const selectionState = getTourSelectionState(booking, tourId);
	const { selectedTourDate, selectedTourTime } = selectionState;
	const inventory = getAvailableInventory(
		{
			date: selectedTourDate,
			time: selectedTourTime,
			tourId: Number(tourId),
		},
		pricing,
	);

	if (inventory) {
		return inventory.priceProfile;
	}

	return null;
};

export const getPaxValidation = (pricing: any, booking: any) => {
	const inventory = getSelectedInventory(pricing, booking);

	if (inventory) {
		return inventory.paxValidation;
	}

	const { seatmapEmbeddedUrl } = pricing;
	const { seatMapInfo } = booking;

	if (seatmapEmbeddedUrl && seatMapInfo) {
		return {
			ADULT: {
				displayName: 'Adult',
				description: '',
				minPax: 0,
				maxPax: 1742,
				ageFrom: null,
				ageTo: null,
			},
		};
	}

	return null;
};

export const getTourPaxValidation = (
	pricing: any,
	booking: any,
	tourId: any,
) => {
	const selectionState = getTourSelectionState(booking, tourId);
	const { selectedTourDate, selectedTourTime } = selectionState;
	const inventory = getAvailableInventory(
		{
			date: selectedTourDate,
			time: selectedTourTime,
			tourId: Number(tourId),
		},
		pricing,
	);

	if (inventory) {
		return inventory.paxValidation;
	}

	return null;
};

export const getNetPricePayable = ({
	// @ts-expect-error TS(7031): Binding element 'pricing' implicitly has an 'any' ... Remove this comment to see the full error message
	pricing,
	// @ts-expect-error TS(7031): Binding element 'booking' implicitly has an 'any' ... Remove this comment to see the full error message
	booking,
	deductPromoCodeDiscount = true,
	walletCredits = 0,
}) => {
	const priceProfile = getPriceProfile(pricing, booking);

	let totalPrice = getTotalPriceForProfile(booking, pricing, priceProfile);

	const promoDiscount = booking?.promoObject?.promoCodeDiscount;
	if (deductPromoCodeDiscount && promoDiscount) {
		totalPrice -= promoDiscount;
	}
	totalPrice = Math.max(totalPrice - walletCredits, 0);

	return totalPrice;
};

export const getTotalPriceForProfile = (
	booking: any,
	pricing: any,
	priceProfile: any,
) => {
	const pricesWithSelections = getPricesWithSelections(
		booking,
		pricing,
		priceProfile,
	);

	const { priceProfileType } = priceProfile;
	let totalPrice = 0;
	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		totalPrice = pricesWithSelections
			.map(priceWithSelection => {
				const { listingPrice, number } = priceWithSelection;
				return number * listingPrice;
			})
			.reduce((total, curr) => total + curr, 0);
	} else {
		totalPrice = pricesWithSelections[0].listingPrice;
	}

	return totalPrice;
};

/**
 * @param personType
 * @param selectionMap stored in Booking
 * @returns Number corresponding to the personType in the map.
 */
export const getSelectedNumberFromMap = (
	personType: any,
	selectionMap: any,
) => {
	if (personType && selectionMap) {
		return selectionMap?.[personType.toUpperCase()];
	}
	return null;
};

export const getAMGroupProfile = (groupSize: any) => ({
	people: groupSize,
});

export const getPeopleProfile = ({
	selectionMap,
	groupSize,
	priceProfile,
}: any) => {
	const type =
		groupSize > 0 ? PROFILE_TYPE.PER_GROUP : PROFILE_TYPE.PER_PERSON;
	const groupProfile = getAMGroupProfile(groupSize);
	const personProfiles = priceProfile?.persons
		.filter(({ type }: any) => selectionMap[type] > 0)
		.map(({ type }: any) => ({
			type,
			people: selectionMap[type],
		}));

	return {
		type,
		personProfiles,
		groupProfile,
	};
};

export const getAMCustomerUser = ({ booking }: any) => {
	// @ts-expect-error TS(7034): Variable 'keyValueUserDetails' implicitly has type... Remove this comment to see the full error message
	const keyValueUserDetails = [];
	booking?.userFields.forEach((user: any, index: any) => {
		// @ts-expect-error TS(7034): Variable 'singleUserFields' implicitly has type 'a... Remove this comment to see the full error message
		const singleUserFields = [];
		user.forEach((field: any) => {
			if (!field) return;
			// vendorId has been mutated in bookingStore as per selection so validate the vendor if in future we want to pass that to bookingAPIObject
			const fieldObj = {
				tourUserFieldId: field?.id,
				tourUserFieldType: field?.name,
				tourUserCustomFieldType: field?.customFieldType,
				customerNumber: index + 1,
				name: field?.displayName,
				value: field?.value,
				required: field?.required,
			};
			singleUserFields.push(fieldObj);
		});
		if (singleUserFields.length) {
			// @ts-expect-error TS(7005): Variable 'singleUserFields' implicitly has an 'any... Remove this comment to see the full error message
			keyValueUserDetails.push(singleUserFields);
		}
	});
	// @ts-expect-error TS(7005): Variable 'keyValueUserDetails' implicitly has an '... Remove this comment to see the full error message
	return keyValueUserDetails;
};

export const getTourUserDetails = ({ booking, tourId }: any) => {
	// @ts-expect-error TS(7034): Variable 'keyValueUserDetails' implicitly has type... Remove this comment to see the full error message
	const keyValueUserDetails = [];
	const tourUserFields = getTourSelectionState(
		booking,
		tourId,
	)?.tourUserFields;
	tourUserFields.forEach((user: any, index: any) => {
		// @ts-expect-error TS(7034): Variable 'singleUserFields' implicitly has type 'a... Remove this comment to see the full error message
		const singleUserFields = [];
		user.forEach((field: any) => {
			if (!field) return;
			// vendorId has been mutated in bookingStore as per selection so validate the vendor if in future we want to pass that to bookingAPIObject
			const fieldObj = {
				tourUserFieldId: field?.id,
				tourUserFieldType: field?.name,
				tourUserCustomFieldType: field?.customFieldType,
				customerNumber: index + 1,
				name: field?.displayName,
				value: field?.value,
				required: field?.required,
			};
			singleUserFields.push(fieldObj);
		});
		if (singleUserFields.length) {
			// @ts-expect-error TS(7005): Variable 'singleUserFields' implicitly has an 'any... Remove this comment to see the full error message
			keyValueUserDetails.push(singleUserFields);
		}
	});
	// @ts-expect-error TS(7005): Variable 'keyValueUserDetails' implicitly has an '... Remove this comment to see the full error message
	return keyValueUserDetails;
};

export const updateBookingDateTimezone = (bookingDetails: any) => {
	const { booking, originalTimezone, targetTimezone } = bookingDetails;
	const { selectedDate: viewSelectedDate, selectedTime: viewSelectedTime } =
		booking;
	const { date: selectedDate, time: selectedTime } = datetimeStrToTimezone({
		dateStr: viewSelectedDate,
		timeStr: viewSelectedTime,
		originalTimezone: originalTimezone || getLocalTimezone(),
		targetTimezone,
	});
	return {
		...booking,
		selectedTime: selectedTime,
		selectedDate,
	};
};

export const getBookingDetails = ({
	pricing,
	booking,
	comment,
	reserveSessionId,
	breakup,
	isExternalSeatmap = false,
}: {
	pricing: Pricing;
	booking: Booking;
	comment?: string;
	reserveSessionId?: string;
	breakup: BreakupResponse;
	isExternalSeatmap?: boolean;
}) => {
	const {
		id,
		selectedDate,
		selectedTime,
		selectedTourId,
		selectionMap,
		groupSize,
		seatMapInfo,
		itineraryComment,
		airportTransfers,
	} = booking;

	const priceProfile = getPriceProfile(pricing, booking);
	const peopleProfile = getPeopleProfile({
		selectionMap,
		groupSize,
		priceProfile,
	});
	const inventoryTime = getFirstAvailableTimeAcrossTours(
		pricing,
		selectedDate,
		selectedTime,
	);
	const pricePayable = Number(getPricePayable(breakup));
	const clientLanguage = booking?.language;
	const clientTimezone = getLocalTimezone();
	const clientCurrency = getClientCurrency(pricing);
	const billableValue = getBillablePrice(breakup);
	const billableCurrency = getBillableCurrencyCode(breakup);

	const airportTransferQuoteId = airportTransfers?.quoteId;

	const newReserveSessionId = getBookingReserveSessionId(booking, id);

	return {
		comment: `${getSmartLookUrl() || ''} | ${
			getClarityUrl() ?? ''
		} ${comment}`,
		inventoryDate: selectedDate,
		tourGroupId: id,
		inventoryTime,
		tourId: selectedTourId,
		peopleProfile,
		pricePayable,
		seatInfos: isExternalSeatmap ? null : seatMapInfo,
		clientCurrency,
		clientLanguage,
		billableValue,
		billableCurrency,
		itineraryComment,
		clientTimezone,
		reserveSessionId,
		...(airportTransferQuoteId && {
			airportTransferQuoteId,
		}),
		...(newReserveSessionId && {
			reserveSessionId: newReserveSessionId,
		}),
	};
};

const sortCartBookingsArray = ({
	bookings,
	baseProductId,
}: {
	bookings: any[];
	baseProductId: number;
}) => {
	const baseProductBookingObjectIdx = bookings.findIndex(
		bookingObj => bookingObj.id == baseProductId,
	);
	if (baseProductBookingObjectIdx === 0) return bookings;
	const temp = bookings[baseProductBookingObjectIdx];
	bookings[baseProductBookingObjectIdx] = bookings[0];
	bookings[0] = temp;
	return bookings;
};

export const getBreakupRequest = ({
	productIdPricingMap,
	bookings,
	applyingPromo = true,
	comment,
	isExternalSeatmap = false,
	breakup,
	id: baseProductId,
	fetchBreakupForAllBookings,
}: {
	productIdPricingMap: {
		[productId: number]: Pricing;
	};
	bookings: {
		[tgid: number]: Booking;
	};
	applyingPromo?: boolean;
	comment?: string;
	isExternalSeatmap?: boolean;
	fetchBreakupForAllBookings?: boolean;
	breakup: BreakupResponse;
	id: number;
}) => {
	const allBookings = fetchBreakupForAllBookings
		? sortCartBookingsArray({
				bookings: Object.values(bookings),
				baseProductId,
		  })
		: [bookings[baseProductId]];

	const variantBookingBreakupRequestList = allBookings.map(booking => {
		const {
			id,
			selectedDate,
			selectedTime,
			selectedTourId,
			selectionMap,
			groupSize,
			seatMapInfo,
			itineraryComment,
			language: clientLanguage,
			selectedVariantId,
			airportTransfers,
		} = booking;

		const pricing = productIdPricingMap[id];

		const priceProfile = getPriceProfile(pricing, booking);

		const pricePayable = Number(getPricePayable(breakup));

		const peopleProfile = getPeopleProfile({
			selectionMap,
			groupSize,
			priceProfile,
		});
		const time = getFirstAvailableTimeInTourDate(
			pricing,
			selectedTourId,
			selectedDate,
			selectedTime,
		);
		const clientTimezone = getLocalTimezone();

		const billableValue = breakup?.billablePrice?.value;

		const bookingDetails = {
			comment: `${getSmartLookUrl() || ''} | ${
				getClarityUrl() ?? ''
			} ${comment}`,
			inventoryDate: selectedDate,
			inventoryTime: time,
			tourGroupId: String(id),
			tourId: String(selectedTourId),
			peopleProfile,
			pricePayable,
			seatInfos: isExternalSeatmap ? null : seatMapInfo,
			clientLanguage,
			billableValue,
			itineraryComment,
			clientTimezone,
		} as BookingDetailsPayload;

		const reserveSessionId = getBookingReserveSessionId(
			booking,
			booking.id,
		);

		if (reserveSessionId) {
			bookingDetails.reserveSessionId = reserveSessionId;
		}

		const isAirportTransfersFlow = !!airportTransfers?.quoteId;

		if (isAirportTransfersFlow && airportTransfers?.quoteId) {
			bookingDetails.pricePayable = 0;

			bookingDetails.airportTransferQuoteId = airportTransfers.quoteId;
		}

		return {
			bookingDetailsList: [bookingDetails],
			productId: Number(booking.id),
			variantId: Number(selectedVariantId),
		};
	});

	const promoObject = bookings[baseProductId]?.promoObject;

	const appliedPromoCode =
		applyingPromo && promoObject && promoObject?.isApplied
			? promoObject?.appliedPromoCode
			: null;

	const couponCode =
		applyingPromo && promoObject
			? promoObject?.promoCode
			: appliedPromoCode;

	return {
		variantBookingBreakupRequestList,
		clientCurrency: getClientCurrency(productIdPricingMap[baseProductId]),
		couponCode,
	};
};

export const getComboBreakupRequest = ({
	// @ts-expect-error TS(7031): Binding element 'pricing' implicitly has an 'any' ... Remove this comment to see the full error message
	pricing,
	// @ts-expect-error TS(7031): Binding element 'booking' implicitly has an 'any' ... Remove this comment to see the full error message
	booking,
	applyingPromo = true,
	// @ts-expect-error TS(7031): Binding element 'breakup' implicitly has an 'any' ... Remove this comment to see the full error message
	breakup,
}) => {
	const {
		id,
		promoObject,
		seatMapInfo,
		itineraryComment,
		language: clientLanguage,
		selectedVariantId,
		comboSelections,
	} = booking;
	const { tourIds } = pricing;
	const clientCurrency = getClientCurrency(pricing);
	const appliedPromoCode =
		applyingPromo && promoObject?.isApplied
			? promoObject?.appliedPromoCode
			: '';
	const couponCode =
		applyingPromo && promoObject
			? promoObject?.promoCode
			: appliedPromoCode;

	const toursSelected = tourIds.filter(
		(tourId: any) => comboSelections?.[String(tourId)],
	);

	const hasEmptySelections = toursSelected.some((tourId: any) => {
		const tourSelections = comboSelections?.[String(tourId)];

		if (!tourSelections) return true;

		const {
			selectedTourDate,
			selectedTourTime,
			tourSelectionMap,
			tourGroupSize,
		} = tourSelections;

		const time = getFirstAvailableTimeInTourDate(
			pricing,
			tourId,
			selectedTourDate,
			selectedTourTime,
		);

		return (
			!selectedTourDate || !time || !(tourSelectionMap || tourGroupSize)
		);
	});

	if (hasEmptySelections) return null;

	const hasAvailableInventory = Object.entries(comboSelections).every(
		([tourId, comboSelectionMap]: any) => {
			const { selectedTourDate, selectedTourTime } = comboSelectionMap;
			const inventory = getAvailableInventory(
				{
					date: selectedTourDate,
					time: selectedTourTime,
					tourId: Number(tourId),
				},
				pricing,
			);

			return !!inventory;
		},
	);

	if (!hasAvailableInventory) return null;

	const bookingDetails = toursSelected.map((tourId: any) => {
		const tourSelections = comboSelections?.[String(tourId)];

		if (!tourSelections) return {};

		const {
			selectedTourDate,
			selectedTourTime,
			tourSelectionMap,
			tourGroupSize,
		} = tourSelections;
		const tourPriceProfile = getTourPriceProfile(pricing, booking, tourId);
		const peopleProfile = getPeopleProfile({
			selectionMap: tourSelectionMap,
			groupSize: tourGroupSize,
			priceProfile: tourPriceProfile,
		});
		const time = getFirstAvailableTimeInTourDate(
			pricing,
			tourId,
			selectedTourDate,
			selectedTourTime,
		);

		const pricePayable = Number(getPricePayable(breakup));
		const clientTimezone = getLocalTimezone();
		const billableValue = breakup?.billablePrice?.value;

		return {
			comment: `${getSmartLookUrl() || ''} | ${getClarityUrl() ?? ''}`,
			inventoryDate: selectedTourDate,
			inventoryTime: time,
			tourGroupId: id,
			tourId,
			peopleProfile,
			pricePayable,
			seatInfos: seatMapInfo,
			clientLanguage,
			billableValue,
			itineraryComment,
			clientTimezone,
		};
	});

	if (!bookingDetails?.length) {
		return null;
	}

	const variantBookingBreakupDetails = {
		bookingDetailsList: bookingDetails,
		productId: Number(id),
		variantId: Number(selectedVariantId),
	};

	return {
		variantBookingBreakupRequestList: [variantBookingBreakupDetails],
		clientCurrency,
		couponCode,
	};
};

export const getVariantBookingRequest = ({
	comment,
	product,
	bookings,
	pricingMap,
	breakup,
}: any) => {
	const allBookings = Object.values(bookings) as Booking[];

	const variantBookingRequests = sortCartBookingsArray({
		bookings: allBookings,
		baseProductId: product?.id,
	})
		.filter(bookingObject => bookingObject)
		.map((booking: Booking) => {
			const {
				itineraryComment,
				seatMapInfo,
				language,
				comboSelections,
				selectedVariantId,
			} = booking;

			const pricing = pricingMap[booking.id];

			if (!pricing) return null;

			const { tourIds } = pricing;
			const { id } = product;
			const clientCurrency = getClientCurrency(pricing);
			const isExternalSeatmap = isExternalSeatmapProduct(product);
			let bookingRequestList;

			if (isComboVariant(pricing, selectedVariantId)) {
				bookingRequestList = tourIds.map((tourId: any) => {
					const tourSelections = comboSelections?.[String(tourId)];
					if (!tourSelections) return {};

					const {
						selectedTourDate,
						selectedTourTime,
						tourSelectionMap,
						tourGroupSize,
					} = tourSelections;
					const tourPriceProfile = getTourPriceProfile(
						pricing,
						booking,
						tourId,
					);
					const peopleProfile = getPeopleProfile({
						selectionMap: tourSelectionMap,
						groupSize: tourGroupSize,
						priceProfile: tourPriceProfile,
					});
					const time = getFirstAvailableTimeInTourDate(
						pricing,
						tourId,
						selectedTourDate,
						selectedTourTime,
					);

					const clientTimezone = getLocalTimezone();
					const pricePayable = Number(getPricePayable(breakup));
					const billableValue = getBillablePrice(breakup);
					const billableCurrency = getBillableCurrencyCode(breakup);

					const listBookingUserFields = getTourUserDetails({
						booking,
						tourId,
					});

					const reserveSessionId = getBookingReserveSessionId(
						booking,
						id,
					);

					const bookingDetails = {
						comment: `${getSmartLookUrl() || ''} | ${
							getClarityUrl() ?? ''
						} ${comment}`,
						inventoryDate: selectedTourDate,
						inventoryTime: time,
						tourGroupId: id,
						tourId,
						peopleProfile,
						pricePayable,
						seatInfos: isExternalSeatmap ? null : seatMapInfo,
						clientCurrency,
						clientLanguage: language,
						billableValue,
						billableCurrency,
						itineraryComment,
						clientTimezone,
						...(reserveSessionId
							? {
									reserveSessionId,
							  }
							: {}),
					};

					return {
						bookingDetails,
						listBookingUserFields,
					};
				});
			} else {
				const getDetails = isSeatmap(pricing)
					? getSeatmapBookingDetails
					: getBookingDetails;
				const bookingDetailsArgument = {
					pricing,
					booking,
					breakup,
					comment,
					applyingPromo: true,
					isExternalSeatmap,
				};
				if (isReservationFlow(product)) {
					const { selectedVariantId } = booking;
					const savedSessions = JSON.parse(
						read(RESERVATION_SESSION_STORAGE_KEY),
					);
					const isBroadwaySessionExpired = hasReservedSessionExpired({
						product,
						booking,
					});
					if (
						!!savedSessions &&
						!!savedSessions[id] &&
						!!savedSessions[id][selectedVariantId] &&
						!isBroadwaySessionExpired
					) {
						(bookingDetailsArgument as any).reserveSessionId =
							savedSessions[id][
								selectedVariantId
							].reserveSessionId;
					}
				}
				const bookingDetails = getDetails(
					bookingDetailsArgument as any,
				);
				const listBookingUserFields = getAMCustomerUser({
					booking,
				});
				const bookingRequest = {
					bookingDetails,
					listBookingUserFields,
				};
				bookingRequestList = [bookingRequest];
			}
			const variantBookingRequest = {
				bookingRequestList,
				productId: Number(booking.id),
				variantId: Number(selectedVariantId),
			};

			return variantBookingRequest;
		})
		.filter(request => request !== null);

	return variantBookingRequests;
};

export const getBookingData = ({
	pricingMap,
	bookings,
	paymentInfo,
	comment,
	breakup,
	affiliateMetadata,
	paymentGateway,
	paymentMethod,
	externalPaymentSourceInfo,
	subscriptions,
	product,
	securityParam,
}: any) => {
	const booking = bookings[product?.id];
	const pricing = pricingMap[product?.id];
	const { language, fingerprintVisitorDetails, browserInfo } = booking;
	const clientCurrency = getClientCurrency(pricing);
	const couponCode = getAppliedPromoCode(booking);
	const finalPrice = getPricePayable(breakup);
	const variantBookingRequestList = getVariantBookingRequest({
		bookings,
		comment,
		pricingMap,
		product,
		breakup,
	});
	const redirectType =
		paymentMethod === PAYMENT_METHOD.CARD
			? THREE_DS_REDIRECT_TYPE.FAILURE
			: paymentMethod;
	const threeDSFailureRedirectUrl = `${window?.location.href}&${QUERY_PARAM.REDIRECT}=${redirectType}`;
	const paymentDetails = {
		cardDetails:
			paymentMethod === PAYMENT_METHOD.CARD && finalPrice !== 0
				? paymentInfo
				: null,
		paymentGateway,
		paymentMethod,
		usingHeadoutWalletPay: true,
		couponCode,
		externalPaymentSourceInfo,
		threeDSFailureRedirectUrl,
		browserInfo: browserInfo ?? null,
	};

	let fingerPrintDetails = null;
	if (fingerprintVisitorDetails) {
		const {
			incognito,
			ip,
			visitorId,
			requestId,
			browserName,
			sealedResult,
		} = fingerprintVisitorDetails;
		fingerPrintDetails = {
			visitorId,
			requestId,
			sealedResult,
			browser: browserName,
			isIncognito: incognito,
			ipAddress: ip,
		};
	}

	return {
		variantBookingRequestList,
		paymentDetails,
		affiliateMetadata,
		clientCurrency,
		clientLanguage: language,
		subscriptions,
		fingerPrintDetails,
		securityParam,
	};
};

export const getTimeSlot = (
	pricing: any,
	tourDetails: any,
	availableSeatsMap: any,
) => {
	const { date, time, tourId } = tourDetails;
	const { currency } = pricing;
	const inventories = getInventoriesForTuple({ date, time, tourId }, pricing);
	if (!inventories) return null;

	const availability = getCombinedAvailability(
		inventories,
		availableSeatsMap,
	);
	const priceTag = getMinPriceTag({ inventories, currency });
	return { time, priceTag, availability };
};

export const getCombinedAvailability = (
	inventories: any,
	availableSeatsMap: any,
) => {
	if (!inventories)
		return {
			isLimited: true,
			seatsLeft: 0,
		};
	const combineAvailability = (combined: any, cur: any) => {
		const { isLimited: isCombinedLimited, seatsLeft: combinedSeatsLeft } =
			combined;
		const { isLimited: isCurLimited, seatsLeft: curSeatsLeft } = cur;
		return {
			isLimited: isCombinedLimited && isCurLimited,
			seatsLeft: combinedSeatsLeft + curSeatsLeft,
		};
	};

	return inventories
		.map((inv: any) => {
			const {
				availability,
				startDate: date,
				startTime: time,
				tourId,
			} = inv;
			const filteredSeatMapData = filterSeatMapBySlot({
				availableSeatsMap,
				date,
				time,
				tourId,
			});
			let { remaining } = inv;
			if (filteredSeatMapData.length) {
				remaining = filteredSeatMapData?.[0]?.availableSeats.length;
			}
			// possible values of availability are UNLIMITED, LIMITED, CLOSED
			const isLimited = availability !== AVAILABILITY_TYPE.UNLIMITED;
			return { isLimited, seatsLeft: isLimited ? remaining : 0 };
		})
		.reduce(combineAvailability, { isLimited: true, seatsLeft: 0 });
};

export const formatUserFields = (
	userFields: customUserFieldType[],
	selectedVendorId: number,
): customUserFieldModelType[] =>
	userFields.map((field: customUserFieldType) =>
		createUserFieldModel(field, selectedVendorId),
	);

export const getFreshUserFieldsArray = ({
	pricing,
	booking,
	tourId,
	selectionMap,
	groupSize,
}: any) => {
	const isCombo = isComboVariant(pricing, booking?.selectedVariantId);
	const vendorId = isCombo
		? getComboSelectedTourVendorId({
				booking,
				tourId: Number(tourId),
				pricing,
		  })
		: (getSelectedInventory(pricing, booking) ?? {}).vendorId;

	const { vendorLevelUserFields = {}, defaultUserFields } =
		pricing?.userFields?.[booking?.selectedVariantId]?.[tourId] ?? {};

	const vendorLevelFields =
		vendorLevelUserFields[vendorId] ?? defaultUserFields;

	const unFormattedUserFields = isNonEmptyObject(vendorLevelUserFields)
		? vendorLevelFields
		: defaultUserFields;

	if (!unFormattedUserFields) {
		return null;
	}

	const userFields = formatUserFields(unFormattedUserFields, vendorId);

	const customerCount = getCustomerCount({
		selectionMap,
		groupSize,
	});

	const primaryCustomerFields: any[] = [];
	const allCustomerFields: any[] = [];
	const priceProfile = isCombo
		? getTourPriceProfile(pricing, booking, tourId)
		: getPriceProfile(pricing, booking);
	const guestList = getGuestList({ priceProfile, selectionMap, groupSize });

	userFields.forEach((field: any) => {
		const fieldStruct = JSON.parse(JSON.stringify(field));
		fieldStruct.edited = false;

		// level
		if (field.level === FIELD_LEVEL.PRIMARY_CUSTOMER) {
			primaryCustomerFields.push(fieldStruct);
		} else {
			// ALL_CUSTOMER
			allCustomerFields.push(fieldStruct);
			primaryCustomerFields.push(fieldStruct);
		}
	});

	let customerArrayWithFields;
	if (customerCount > 0 && allCustomerFields.length > 0) {
		customerArrayWithFields = guestList
			?.slice(1)
			.map(() => JSON.parse(JSON.stringify(allCustomerFields)));
	}

	let userFieldsArray = [];

	if (PlatformUtils.isDesktop()) {
		// only switch phone and email fields in desktop
		[primaryCustomerFields[1], primaryCustomerFields[2]] = [
			primaryCustomerFields[2],
			primaryCustomerFields[1],
		];
	}

	userFieldsArray.push(primaryCustomerFields);
	if (allCustomerFields.length > 0 && customerCount > 0) {
		userFieldsArray = userFieldsArray.concat(customerArrayWithFields);
	}

	return userFieldsArray;
};

export const getUserFieldsArray = ({
	pricing,
	booking,
	tourId,
	oldUserFields,
	selectionMap,
	groupSize,
}: any) => {
	if (!oldUserFields) {
		return getFreshUserFieldsArray({
			pricing,
			booking,
			tourId,
			selectionMap,
			groupSize,
		});
	}

	const isCombo = isComboVariant(pricing, booking?.selectedVariantId);
	const vendorId = isCombo
		? getComboSelectedTourVendorId({
				booking,
				tourId: Number(tourId),
				pricing,
		  })
		: (getSelectedInventory(pricing, booking) ?? {}).vendorId;
	const oldVendorId = oldUserFields?.[0]?.[0]?.vendorId;
	const oldTourId = oldUserFields?.[0]?.[0]?.tourId;

	if (Number(oldTourId) !== Number(tourId) || oldVendorId !== vendorId) {
		return getFreshUserFieldsArray({
			pricing,
			booking,
			tourId,
			selectionMap,
			groupSize,
		});
	}

	let newUserFields = oldUserFields;
	const customerCount = getCustomerCount({
		selectionMap,
		groupSize,
	});
	// in this case just add or remove entries, take care of 0 customerCount
	if (customerCount === 0) {
		// keep only primary user
		newUserFields = oldUserFields?.slice(0, 1);
	} else if (oldUserFields.length === 1) {
		if (customerCount > 1) {
			const tempUserFields = getFreshUserFieldsArray({
				pricing,
				booking,
				tourId,
				selectionMap,
				groupSize,
			});
			newUserFields = tempUserFields?.slice(1);
			newUserFields.unshift(oldUserFields?.[0]);
			return newUserFields;
		}
		return oldUserFields;
	} else if (customerCount > oldUserFields.length) {
		const tempUserFields = getFreshUserFieldsArray({
			pricing,
			booking,
			tourId,
			selectionMap,
			groupSize,
		});
		const endOfOldUserFields = tempUserFields?.slice(oldUserFields.length);
		newUserFields = [].concat(oldUserFields);
		newUserFields = newUserFields.concat(endOfOldUserFields);
		return newUserFields;
	} else if (customerCount < oldUserFields.length) {
		newUserFields = oldUserFields.slice(0, customerCount);
	}
	return newUserFields;
};

// Wallet credits a user has.
export const getWalletCredits = (user: any) => {
	if (!user) return 0;
	const { walletCredits, walletToBaseCurrencyConversionRate } = user;
	if (walletToBaseCurrencyConversionRate) {
		return walletCredits * walletToBaseCurrencyConversionRate;
	}
	return walletCredits;
};

export const isOnRequest = (pricing: any, booking: any, cookies?: any) => {
	return isAffiliate(cookies) && !shouldAllowBooking(pricing, booking);
};

/* Returns value in email field from custom fields.
 * WARNING: Algo is O(N)
 */
export const getUserEmailFromUserFields = ({ userFields }: any) => {
	const emailIndex = userFields?.[0]?.findIndex(
		(field: any) => field?.name === FIELD_NAME.EMAIL,
	);
	// If field is not found, index will be -1. Return null in that case.
	return emailIndex !== -1 ? userFields?.[0]?.[emailIndex]?.value : null;
};

/* Returns value in name field from custom fields.
 * WARNING: Algo is O(N)
 */
export const getNameFromUserFields = ({ userFields }: any) => {
	const nameIndex = userFields?.[0].findIndex(
		(field: any) => field?.name === FIELD_NAME.NAME,
	);
	return nameIndex !== -1 ? userFields?.[0]?.[nameIndex]?.value : null;
};

/* Returns value in phone field from custom fields.
 * WARNING: Algo is O(N)
 */
export const getPhoneNumberFromUserFields = ({ userFields }: any) => {
	const phoneIndex = userFields?.[0].findIndex(
		(field: any) => field?.name === FIELD_NAME.PHONE,
	);
	return phoneIndex !== -1 ? userFields?.[0]?.[phoneIndex]?.value : null;
};

export const getCustomerEmail = (user: any, booking: any) => {
	if (user) {
		const { email } = user;
		return email;
	}
	const { userFields } = booking;
	return getUserEmailFromUserFields({ userFields });
};

export const getUserFieldIndex = (userFields: any, fieldName: any) => {
	return userFields?.[0].findIndex((item: any) => item?.name === fieldName);
};

export const getPaxCount = (selectionMap: any) => {
	let paxCount = 0;
	selectionMap.forEach((value: any) => {
		paxCount += value;
	});
	return paxCount;
};

export const getAddressLine = (startPoint: any) => {
	let { addressLine1: address } = startPoint;
	const { addressLine2, cityName, postalCode, state, countryCode } =
		startPoint;
	const addressArray = [];
	addressArray.push(addressLine2, cityName, postalCode, state, countryCode);
	addressArray.forEach(item => {
		address = item ? `${address}, ${item}` : address;
	});
	return address;
};

export const isBroadwayShow = (product: any) => {
	const { flowType } = product;
	if (flowType) return flowType === BOOKING_FLOW_TYPE.RESERVATION;
	else return false;
};

export const isLttShow = (host: any) => host.includes('london-theater-tickets');

export const getTourSelectionState = (booking: any, tourId: any) =>
	booking?.comboSelections?.[String(tourId)];

export const getTourUserFields = (booking: any, tourId: any) =>
	getTourSelectionState(booking, tourId)?.tourUserFields;

export const getCommonTourUserFields = (booking: any) =>
	(Object.values(booking?.comboSelections)?.[0] as any)?.tourUserFields;

/**
 * @param flowType
 * @param price
 * @param currencyCode
 * @param hasCurrencyParam: boolean
 * @param toursCount
 * @returns triggers the event for select page.
 */
export const triggerSelectPageEvents = ({
	flowType,
	price,
	currencyCode,
	hasCurrencyParam,
	toursCount,
	id,
	mbName,
	isLivePricingEnabled,
	numberOfFilters,
}: any) => {
	if (!hasCurrencyParam) {
		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.CURRENCY,
			value: currencyCode,
		});
	}

	if (
		flowType === ANALYTICS_FLOW_TYPE.HIFI_SEATMAP ||
		flowType === ANALYTICS_FLOW_TYPE.LOFI_SEATMAP
	) {
		trackPageView({
			pageType: PAGE_TYPE.SELECT,
			[ANALYTICS_PROPERTIES.TGID]: Number(id),
			[ANALYTICS_PROPERTIES.FLOW_TYPE]: flowType,
			[ANALYTICS_PROPERTIES.MB_NAME]: mbName,
			[ANALYTICS_PROPERTIES.FB_PIXEL_VALUE]: price,
			[ANALYTICS_PROPERTIES.FB_PIXEL_CURRENCY]: currencyCode,
			[ANALYTICS_PROPERTIES.NO_OF_TOUS]: toursCount,
			[ANALYTICS_PROPERTIES.LIVE_PRICING_ENABLED]: isLivePricingEnabled,
			...(numberOfFilters
				? {
						[ANALYTICS_PROPERTIES.NUMBER_OF_FILTERS]:
							numberOfFilters,
				  }
				: {}),
		});
	} else {
		trackPageView({
			pageType: PAGE_TYPE.SELECT,
			[ANALYTICS_PROPERTIES.TGID]: Number(id),
			[ANALYTICS_PROPERTIES.FLOW_TYPE]: flowType,
			[ANALYTICS_PROPERTIES.FB_PIXEL_VALUE]: price,
			[ANALYTICS_PROPERTIES.FB_PIXEL_CURRENCY]: currencyCode,
			[ANALYTICS_PROPERTIES.NO_OF_TOUS]: toursCount,
			[ANALYTICS_PROPERTIES.LIVE_PRICING_ENABLED]: isLivePricingEnabled,
		});
	}
};

export const initialBookingState = {
	bookingId: null,
	bookingResponse: {
		bookedStatus: null,
		duration: 0,
		freeTourInfo: {
			id: null,
			name: '',
			biLink: '',
			discount: null,
			code: null,
		},
		id: 0,
		inventoryDiscount: 0,
		inventoryLocalDate: '',
		inventoryLocalTime: '',
		paymentTransactionId: null,
		priceCouponDiscount: 0,
		pricePayable: 0,
		pricePayableClientCurrencyAmount: 0,
		pricePayableClientCurrencyCode: '',
		pricePayableClientCurrencySymbol: '',
		pricePayableCurrencyCode: '',
		pricePayableUsd: 0,
		seatmapInfo: [],
		secureId: '',
		securePaymentVerificationInfo: {
			performRedirect: null,
			redirectUrl: null,
			redirectType: null,
		},
		status: '',
		totalCashback: 0,
		totalExtraCharges: 0,
		transactionAmount: 0,
		uiOrderId: null,
	},
	comboSelections: {},
	groupSize: 0,
	id: null,
	instantCheckout: false,
	isBookButtonLocked: false,
	itineraryComment: null,
	itineraryDetails: null,
	language: null,
	paymentError: null,
	paymentCard: null,
	promoObject: null,
	seatMapInfo: null,
	selectedDate: null,
	selectedTime: null,
	selectedTourId: null,
	selectedVariantId: null,
	selectionMap: null,
	stage: null,
	userFields: null,
	fingerprintVisitorDetails: null,
	filterValue: undefined,
	comboUpsell: {
		data: null,
		status: {
			isFetching: null,
		},
	},
};

export const getPrimaryUserTraitsFromBooking = ({ booking, isCombo }: any) => {
	if (!booking) return null;
	let userFields;
	if (isCombo) {
		userFields = getCommonTourUserFields(booking);
	} else {
		userFields = booking?.userFields;
	}
	const jsUserFields = userFields?.[0];
	return jsUserFields.reduce((acc: any, field: any) => {
		if (field['level'] === FIELD_LEVEL.PRIMARY_CUSTOMER)
			return { ...acc, [field['name'].toLowerCase()]: field['value'] };
		return acc;
	}, {});
};

export const shouldShowValidity = (validityType: any) => {
	return (
		validityType === VALIDITY_TYPES.UNTIL_DATE ||
		validityType === VALIDITY_TYPES.UNTIL_DAYS_FROM_PURCHASE
	);
};

export const hasCustomerCountChanged = (booking: any, prevBooking: any) =>
	booking?.selectionMap !== prevBooking?.selectionMap ||
	booking?.groupSize !== prevBooking?.groupSize;

export const getUnchosenTours = (tourIds: number[], tourSelections: any) => {
	const selectedDateTourIds = Object.keys(tourSelections);
	return tourIds
		.map((_: any, index: number) => index)
		.filter(
			(index: number) =>
				!selectedDateTourIds.includes(String(tourIds[index])),
		);
};

export const getTotalPriceForAllSeats = (seats: any, currency: ICurrency) => {
	let totalPrice = 0,
		scratchPrice = 0;
	if (seats && seats.length > 0) {
		seats?.forEach((seat: any) => {
			if (!seat) return;
			totalPrice += parseFloat(seat.price);
			scratchPrice += parseFloat(seat.originalPrice);
		});
	}

	return {
		totalPrice: formatPrice(totalPrice, currency),
		scratchPrice:
			totalPrice !== scratchPrice
				? formatPrice(scratchPrice, currency)
				: '',
		totalPriceWithoutCurrency: totalPrice,
		scratchPriceWithoutCurrency: scratchPrice,
	};
};

export const getComboSelectedTourVendorId = ({
	booking,
	tourId,
	pricing,
}: {
	booking: any;
	tourId: number;
	pricing: any;
}) => {
	const { selectedTourDate: date, selectedTourTime: time } =
		getTourSelectionState(booking, tourId) ?? {};

	const inventory =
		getAvailableInventory({ date, time, tourId }, pricing) ?? {};

	return inventory.vendorId;
};

export function findTourId(
	pricing: any,
	selectedDate: string,
	selectedTime: string,
) {
	const entries = pricing.inventoryMapByDateTime[selectedDate];
	if (!entries) return null;

	const subEntries = selectedTime ? entries[selectedTime] : entries;
	const foundEntry = find(subEntries, 'tourId');

	return foundEntry ? foundEntry.tourId : null;
}

export function findVariantId(pricing: any, tourId: string) {
	const tours = pricing.tourMap[tourId];
	if (!tours) return null;

	const foundTour = find(tours, 'variantId');

	return foundTour ? foundTour.variantId : null;
}

export function getBookingReserveSessionId(booking: any, id: number) {
	return booking?.reserveSessions?.sessions?.[id][booking?.selectedVariantId]
		?.reserveSessionId;
}

export const isGuidedTourExperiment = (product: any) => {
	const tourVariants = product?.variants?.map(({ tours }: any) => tours[0]);
	const isGuidedTour = Object.keys(
		generatePotentialPropertyValuesMap(tourVariants),
	)?.includes(TOUR_PROPERTIES.LANG);

	return isGuidedTour;
};

export const getTotalGuestCount = (booking: any) => {
	if (booking?.groupSize ?? booking?.tourGroupSize) {
		return booking.groupSize ?? booking.tourGroupSize;
	}

	if (booking?.seatMapInfo) {
		return booking.seatMapInfo.length;
	}

	const selectionMap: Record<string, number> =
		booking?.selectionMap || booking?.tourSelectionMap;

	return Object.values(selectionMap ?? {}).reduce(
		(acc: number, selectionValue: number) => acc + selectionValue,
		0,
	);
};

export const getTotalGuestTypeCount = (booking: any) => {
	if (booking?.groupSize ?? booking?.tourGroupSize) {
		return booking.groupSize ?? booking.tourGroupSize;
	}

	if (booking?.seatMapInfo) {
		return booking.seatMapInfo.length;
	}

	const selectionMap: Record<string, number> =
		booking?.selectionMap || booking?.tourSelectionMap;

	return Object.values(selectionMap ?? {}).reduce(
		(acc: number, selectionValue: number) =>
			selectionValue > 0 ? acc + 1 : acc,
		0,
	);
};

export const isDVTBookingFlow = (product: any) => {
	if (!product || !product?.flowType) return false;
	switch (product.flowType) {
		case BOOKING_FLOW_TYPE.NORMAL:
		case BOOKING_FLOW_TYPE.PROPERTY_SELECTION:
		case BOOKING_FLOW_TYPE.GUIDED_TOUR_PROPERTY_SELECTION:
			return true;
		default:
			return false;
	}
};
