import { ActionTypes } from 'Actions/actions';

import { DATA_SOURCE_TYPE } from 'Constants/constants';

export const requestProducts = ({ productIds }: any) => ({
	productIds,
	type: ActionTypes.REQUEST_PRODUCTS,
});

export const requestTranslatedProductContent = ({
	productId,
}: {
	productId: string;
}) => ({
	productId,
	type: ActionTypes.REQUEST_TRANSLATED_PRODUCT_CONTENT,
});

// @ts-expect-error TS(7031): Binding element 'productCards' implicitly has an '... Remove this comment to see the full error message
export const receiveProducts = ({ productCards }) => ({
	productCards,
	type: ActionTypes.RECEIVE_PRODUCTS,
});

export const receiveBulkProducts = ({
	productsJson,
}: {
	productsJson: Record<string, any>;
}) => ({
	productsJson,
	type: ActionTypes.RECEIVE_BULK_PRODUCTS,
});

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const requestProduct = ({ id }) => ({
	id,
	type: ActionTypes.REQUEST_PRODUCT,
});

export const receiveProduct = ({ productJson, url }: any) => ({
	productJson,
	url,
	type: ActionTypes.RECEIVE_PRODUCT,
});

export const receiveTranslatedProductContent = ({
	productJson,
	url,
}: {
	productJson: any;
	url: string;
}) => ({
	productJson,
	url,
	type: ActionTypes.RECEIVE_PRODUCT_CONTENT_TRANSLATED,
});

export const requestProductList = ({ cityCode, params }: any) => ({
	cityCode,
	params,
	type: ActionTypes.REQUEST_PRODUCT_LIST,
});

export const requestRecentProductIdList = (payload: {
	cityCode: string;
	params: string;
}) => ({
	...payload,
	type: ActionTypes.REQUEST_PRODUCT_LIST,
});

export const requestProductListByCity = ({
	cityCode,
	params,
}: {
	cityCode: string;
	params: string;
}) => ({
	cityCode,
	params,
	type: ActionTypes.REQUEST_PRODUCT_LIST_BY_CITY,
});
export const receiveProductListByCity = ({
	cityCode,
	params,
	response,
	nextPage = false,
	url,
}: {
	cityCode: string;
	params: string;
	response: any;
	nextPage: boolean;
	url: string;
}) => {
	const { result } = response;
	const { productCards } = result;
	const { items, ...rest } = productCards;
	const productIdList = items.map((item: any) => item.id);

	return {
		cityCode,
		params,
		productCards: items,
		nextPage,
		productIdList,
		type: ActionTypes.RECEIVE_PRODUCT_LIST_BY_CITY,
		receivedAt: Date.now(),
		isFetching: false,
		url,
		...rest,
	};
};

export const receiveProductList = ({
	// @ts-expect-error TS(7031): Binding element 'cityCode' implicitly has an 'any'... Remove this comment to see the full error message
	cityCode,
	// @ts-expect-error TS(7031): Binding element 'params' implicitly has an 'any' t... Remove this comment to see the full error message
	params,
	// @ts-expect-error TS(7031): Binding element 'response' implicitly has an 'any'... Remove this comment to see the full error message
	response,
	nextPage = false,
	dataSource = DATA_SOURCE_TYPE.API,
	// @ts-expect-error TS(7031): Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
	url,
}) => {
	const { unFilteredMetaData, pageData, currency } = response;
	const { items } = pageData;
	const productIdList = items.map((item: any) => item.id);
	const metaData = {
		unFilteredMetaData,
		currency,
	};
	return {
		cityCode,
		params,
		metaData,
		pageData,
		nextPage,
		productCards: items,
		productIdList,
		type: ActionTypes.RECEIVE_PRODUCT_LIST,
		receivedAt: Date.now(),
		dataSource,
		url,
	};
};
export const receiveRecentProductIdList = (payload: {
	cityCode: string;
	productIdList: any[];
	params: string;
}) => {
	return {
		...payload,
		type: ActionTypes.RECEIVE_RECENT_PRODUCT_LIST,
		receivedAt: Date.now(),
	};
};

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const resetProductStore = ({ id }) => ({
	type: ActionTypes.RESET_PRODUCT,
	payload: {
		id: String(id),
	},
});

export const requestSimilarProducts = ({ id }: { id: string | number }) => ({
	id: String(id),
	type: ActionTypes.REQUEST_SIMILAR_PRODUCTS,
});

export const receiveSimilarProducts = ({ id, items }: any) => {
	const productIdList = items.map((item: any) => item.id);
	return {
		id: String(id),
		productCards: items,
		productIdList: productIdList,
		type: ActionTypes.RECEIVE_SIMILAR_PRODUCTS,
	};
};

export const receiveProductFeatureFlags = ({
	id,
	featureFlags,
}: {
	id: string | number;
	featureFlags: Record<string, boolean>;
}) => ({
	id: String(id),
	featureFlags,
	type: ActionTypes.SET_PRODUCT_FEATURE_FLAGS,
});
