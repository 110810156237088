import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getHost } from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	changeLocationCurrency,
	receiveCurrencies,
	requestCurrencies,
} from 'Actions/currency';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { HOME_CURRENCY_MB_LIST } from 'Constants/constants';

export const fetchCurrencies =
	({
		cookieCurrency,
		countryCode,
		currencyFromUrl,
		skipLocationBased = false,
		host,
	}: {
		cookieCurrency?: string;
		countryCode?: string;
		currencyFromUrl?: string;
		skipLocationBased?: boolean;
		host?: string;
	} = {}) =>
	(dispatch: any, getState: any) => {
		const resolvedHost = host ?? getHost(getState());
		const isSupportedMicrobrand = HOME_CURRENCY_MB_LIST.some(mb =>
			resolvedHost?.includes(mb),
		);

		dispatch(requestCurrencies());
		const fetchCurrencyMap = () => {
			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v1/currency/list`;

			return fetch(url)
				.then(response => response.json())
				.then(json => {
					dispatch(receiveCurrencies({ currenciesList: json, url }));
				})
				.catch(err => {
					dispatch(setAPIServerAPIStatus(url, err.status));
					error(err);
					dispatch(decrementAPICount());
					notify.showNetworkError(err);
				});
		};

		const fetchUserLocationCurrency = () => {
			// do we want to support this for non Headout.com white labeled sites?
			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v1/currency?country=${countryCode}`;
			return fetch(url)
				.then(response => response.json())
				.then(json => {
					const { hasSupportedCurrency, currency } = json;
					if (!hasSupportedCurrency) return;

					dispatch(
						changeLocationCurrency({ currencyCode: currency.code }),
					);
				})
				.catch(err => {
					error(err);
					dispatch(decrementAPICount());
					notify.showNetworkError(err);
				});
		};

		const fetchPromisesArr = [fetchCurrencyMap];
		const shouldFetchLocationCurrency =
			isSupportedMicrobrand || (!currencyFromUrl && !cookieCurrency);

		if (!skipLocationBased && shouldFetchLocationCurrency && countryCode) {
			fetchPromisesArr.push(fetchUserLocationCurrency);
		}

		return Promise.all(fetchPromisesArr.map((fn: any) => fn()));
	};
