import { generateFilterKey } from 'Utils/gen';

import { ActionTypes } from 'Actions/actions';
import { TCollectionCardV2 } from 'ReduxTypes/collection';
import { CollectionByIdStoreType } from 'ReduxTypes/collections';

const initialState: CollectionByIdStoreType = {
	byId: {},
	byCardIds: {},
	byCardIdsV2: {},
	byCityCode: {},
	byCategoryId: {},
	bySubcategoryId: {},
	status: {},
	byCityCodeAndPersonaId: {},
	byCityCodeAndSubCategoryId: {},
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const collectionsStore = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_COLLECTIONS_DATA_BY_ID: {
			const { collectionId } = action;
			return {
				...state,
				status: {
					...state.status,
					[collectionId]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_ID: {
			const { data, collectionId } = action;
			return {
				...state,
				byId: {
					...state.byId,
					[collectionId]: {
						...(state.byId[collectionId] ?? {}),
						...data,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_LIST: {
			const { response } = action.payload;

			const { collections } = response;

			const mapObject = {};
			for (const obj of collections) {
				//@ts-expect-error
				mapObject[obj.id] = obj;
			}

			//collections needs to be an object of id and data
			return {
				...state,
				byCardIds: {
					...state.byCardIds,
					...mapObject,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_LIST_BY_CITY: {
			const { response } = action.payload;
			const { result } = response;
			const { collectionCards } = result;
			const { items } = collectionCards;
			const mapObject = {} as { [id: number]: TCollectionCardV2 };
			for (const obj of items) {
				mapObject[obj.id] = obj;
			}

			//collections needs to be an object of id and data
			return {
				...state,
				byCardIdsV2: {
					...state.byCardIdsV2,
					...mapObject,
				},
			};
		}
		case ActionTypes.REQUEST_COLLECTIONS_DATA_BY_CITY_CODE: {
			const { cityCode } = action;
			return {
				...state,
				status: {
					...state.status,
					[cityCode]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_CITY_CODE: {
			const { data, cityCode } = action;
			return {
				...state,
				byCityCode: {
					...state.byCityCode,
					[cityCode]: data,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_CARD_DATA_BY_ID: {
			const { data, collectionId } = action;
			return {
				...state,
				byCardIds: {
					...state.byCardIds,
					[collectionId]: {
						...data,
					},
				},
			};
		}
		case ActionTypes.REQUEST_COLLECTIONS_DATA_BY_CATEGORY_ID: {
			const { categoryId } = action;
			return {
				...state,
				status: {
					...state.status,
					[`CategoryId-${categoryId}`]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_CATEGORY_ID: {
			const { categoryId, data } = action;
			return {
				...state,
				status: {
					...state.status,
					[`CategoryId-${categoryId}`]: false,
				},
				byCategoryId: {
					...state.byCategoryId,
					[categoryId]: data,
				},
			};
		}
		case ActionTypes.REQUEST_COLLECTIONS_DATA_BY_SUBCATEGORY_ID: {
			const { subCategoryId } = action;
			return {
				...state,
				status: {
					...state.status,
					[`SubcategoryId-${subCategoryId}`]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_SUBCATEGORY_ID: {
			const { subCategoryId, data } = action;
			return {
				...state,
				status: {
					...state.status,
					[`SubcategoryId-${subCategoryId}`]: false,
				},
				bySubcategoryId: {
					...state.bySubcategoryId,
					[subCategoryId]: data,
				},
			};
		}
		case ActionTypes.REQUEST_COLLECTIONS_DATA_BY_PERSONA_ID: {
			const { personaAffinityId, cityCode } = action;
			return {
				...state,
				status: {
					...state.status,
					[generateFilterKey(cityCode, personaAffinityId)]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_PERSONA_ID: {
			const { personaAffinityId, cityCode, data } = action;
			const key = generateFilterKey(cityCode, personaAffinityId);
			return {
				...state,
				status: {
					...state.status,
					[key]: false,
				},
				byCityCodeAndPersonaId: {
					...state.byCityCodeAndPersonaId,
					[key]: data,
				},
			};
		}
		case ActionTypes.REQUEST_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID: {
			const { cityCode, subCategoryId } = action.payload;
			return {
				...state,
				status: {
					...state.status,
					[`CityIdSubCategoryId-${cityCode}-${subCategoryId}`]: true,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID: {
			const { cityCode, subCategoryId, data } = action.payload;
			const map = data.reduce((acc: { [id: number]: any }, curr: any) => {
				acc[curr.id] = curr;
				return acc;
			}, {});
			return {
				...state,
				status: {
					...state.status,
					[`CityIdSubCategoryId-${cityCode}-${subCategoryId}`]: false,
				},
				byCityCodeAndSubCategoryId: {
					...state.byCityCodeAndSubCategoryId,
					...map,
				},
			};
		}
		default:
			return state;
	}
};
