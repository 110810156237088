export type CheckoutProps = {
	paymentCard: TPaymentCard;
	onCardHolderNameChange: (value: string) => void;
	setCardValidity: (value: boolean) => void;
	setCheckoutPaymentFieldValidities: (
		paymentFieldValidities: TPaymentFieldValidities,
	) => void;
	forceValidate: boolean;
	publicKey: string;
	isMobile?: boolean;
	paymentGateway: string;
};

export type TPaymentCard = {
	addressCity: string;
	addressCountry: string;
	addressLine1: string;
	addressLine2: string;
	addressState: string;
	addressZip: string;
	cardFirst6: string;
	cardHolderName: string;
	cardLast4: string;
	cardNumber: string;
	cvv: string;
	isValid: boolean;
	month: string;
	year: string;
	paymentFieldValidities?: TPaymentFieldValidities;
	encryptedCardDetails: any;
};
type TPaymentFieldValidities = {
	[CheckoutFieldType.CARD_NUMBER]: boolean;
	[CheckoutFieldType.EXPIRY_DATE]: boolean;
	[CheckoutFieldType.CVV]: boolean;
};

export enum CheckoutFieldType {
	CARD_NUMBER = 'card-number',
	EXPIRY_DATE = 'expiry-date',
	CVV = 'cvv',
}

export const CHECKOUT_ANALYTICS_FIELD_NAME = {
	'card-number': 'Card Number',
	'expiry-date': 'Expiry',
	cvv: 'CVV',
};
