//@ts-nocheck

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { notify } from 'Components/common/notify';

import mapNewToOldCollectionCard from 'Utils/converters/collectionCard';
import mapNewToOldProductCard from 'Utils/converters/productCard';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getCurrentCurrency, getCurrentLanguageCode } from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount, incrementAPICount } from 'Actions/apiCount';
import {
	receiveCollectionByCategoryId,
	receiveCollectionByCityCode,
	receiveCollectionById,
	receiveCollectionByPersonaId,
	receiveCollectionBySubcategoryId,
	receiveCollectionCardById,
	receiveCollectionsByCityIdSubCategoryId,
	requestCollectionByCategoryId,
	requestCollectionByCityCode,
	requestCollectionById,
	requestCollectionByPersonaId,
	requestCollectionBySubcategoryId,
	requestCollectionsByCityIdSubCategoryId,
} from 'Actions/collections';
import { receiveProductList, receiveProducts } from 'Actions/product';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';
import { ECollectionType } from 'ReduxTypes/collection';

import {
	COLLECTION_PAGE_SECTIONS,
	GLOBAL_CITY_CODE,
	PRODUCTS_GRID_DEFAULT_COUNT,
} from 'Constants/constants';

type FetchCollectionInfoByIdArgs = {
	id: number;
	params?: string;
	cityCode?: string;
	lang?: string;
	limit?: number;
	offset?: number;
	includeUnavailable?: boolean;
	includeCarouselSections?: boolean;
	type?: ECollectionType;
};

type FetchCollectionBasicArgs = {
	lang?: string;
	collectionIds: number | Array<number>;
};

export const fetchCollectionProductCardsById =
	({
		id,
		params,
		secondaryCityCode,
		cityCode,
		lang,
		limit = PRODUCTS_GRID_DEFAULT_COUNT,
		offset = 0,
	}) =>
	(dispatch, getState) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const currentLanguageCode = getCurrentLanguageCode(getState());
		const languageParam = currentLanguageCode
			? `language=${currentLanguageCode.toUpperCase()}`
			: '';
		const limitParam = `limit=${limit}`;
		// const unavailableTours = includeUnavailable
		// 	? 'include-unavailable=true' : '';

		// 	const sectionCarousel = includeCarouselSections
		// 		? `include-carousel-sections=1`
		// 		: '';
		const isDesktop = PlatformUtils.isDesktop();
		const platform = `platform=${isDesktop ? 'DESKTOP' : 'WEB'}`;
		const secondaryCityParam = secondaryCityCode
			? `secondary-city=${secondaryCityCode}`
			: '';
		const offsetParam = offset ? `offset=${offset}` : '';

		const queryParams = [
			secondaryCityParam,
			currencyParam,
			languageParam,
			limitParam,
			// unavailableTours,
			// sectionCarousel,
			platform,
			offsetParam,
		];

		const queryString = queryParams.filter(query => !!query).join('&');

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v2/collections/${id}/product-cards/?${queryString}`;

		// dispatch(requestCollectionById(Number(id)));
		dispatch(incrementAPICount());
		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(decrementAPICount());
				const {
					result: { productCards: { items, total } = {} },
				} = json;
				const mappedItems = items.map((item: any) =>
					mapNewToOldProductCard(item, lang.toUpperCase()),
				);

				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: { pageData: { items: mappedItems, total } },
						// TODO: add pagination data if required
						// nextPage: nextUrl !== null,
						// url,
					}),
				);
			})
			.catch(err => {
				dispatch(decrementAPICount());
				dispatch(
					receiveCollectionById(
						Number(id),
						{
							apiError: {
								statusCode: err.status,
							},
						},
						url,
					),
				);
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchCollectionsInfoByIdSections =
	({
		id,
		params,
		cityCode,
		lang,
		limit = PRODUCTS_GRID_DEFAULT_COUNT,
		includeUnavailable = false,
		includeCarouselSections = false,
		offset = 0,
	}: FetchCollectionInfoByIdArgs): ThunkAction<
		void,
		any,
		unknown,
		Action<string>
	> =>
	(dispatch, getState) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const currentLanguageCode = getCurrentLanguageCode(getState());
		const languageParam = currentLanguageCode
			? `language=${currentLanguageCode.toUpperCase()}`
			: '';
		const limitParam = `limit=${limit}`;
		const unavailableTours = includeUnavailable
			? 'include-unavailable=true'
			: '';
		const sectionCarousel = includeCarouselSections
			? `include-carousel-sections=1`
			: '';
		const isDesktop = PlatformUtils.isDesktop();
		const platform = `platform=${isDesktop ? 'DESKTOP' : 'WEB'}`;
		const secondaryCityParam = cityCode ? `secondary-city=${cityCode}` : '';
		const offsetParam = offset ? `offset=${offset}` : '';

		const queryParams = [
			secondaryCityParam,
			currencyParam,
			languageParam,
			limitParam,
			unavailableTours,
			sectionCarousel,
			platform,
			offsetParam,
		];

		const queryString = queryParams.filter(query => !!query).join('&');

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v1/collection/${id}/sections?${queryString}`;

		dispatch(requestCollectionById(Number(id)));
		dispatch(incrementAPICount());
		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(decrementAPICount());
				const {
					collection,
					sections,
					city: { cityCode },
				} = json;
				const { id } = collection;
				const collectionSections = Object.assign([], sections);
				delete json.sections;
				let collectionSectionProductMap = {};
				let carouselSection = [];
				collectionSections.forEach(section => {
					const { name, type, tourGroups: pageData, title } = section;
					const { items, nextUrl } = pageData;
					if (type === COLLECTION_PAGE_SECTIONS.COLLECTION) {
						dispatch(
							receiveProductList({
								cityCode,
								params,
								response: { pageData },
								nextPage: nextUrl !== null,
								url,
							}),
						);
					} else {
						dispatch(receiveProducts({ productCards: items }));
					}
					const tourGroupIds = items.map(({ id }) => id);
					if (type === COLLECTION_PAGE_SECTIONS.CAROUSEL) {
						carouselSection.push({
							name,
							type,
							tourGroupIds,
							title,
						});
					} else {
						collectionSectionProductMap = {
							...collectionSectionProductMap,
							[type]: {
								name,
								type,
								tourGroupIds,
								title,
							},
						};
					}
				});
				collectionSectionProductMap = {
					...collectionSectionProductMap,
					[COLLECTION_PAGE_SECTIONS.CAROUSEL]: carouselSection,
				};
				json = {
					...json,
					collection: {
						...json.collection,
						collectionSectionProductMap,
					},
				};
				dispatch(
					receiveCollectionById(Number(id), json.collection, url),
				);
			})
			.catch(err => {
				dispatch(decrementAPICount());
				dispatch(
					receiveCollectionById(
						Number(id),
						{
							apiError: {
								statusCode: err.status,
							},
						},
						url,
					),
				);
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchCollectionsInfoById =
	({
		id,
		cityCode,
		lang,
		type,
	}: FetchCollectionInfoByIdArgs): ThunkAction<
		void,
		any,
		unknown,
		Action<string>
	> =>
	(dispatch, getState) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const secondaryCityParam = cityCode ? `secondary-city=${cityCode}` : '';

		const queryParams = [secondaryCityParam, currencyParam, langParam];
		const queryString = queryParams.filter(query => !!query).join('&');

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v1/collection/?ids[]=${id}&${queryString}`;

		dispatch(requestCollectionById(Number(id)));
		dispatch(incrementAPICount());

		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(decrementAPICount());
				const { collections } = json;
				const collection = collections[0];
				collection.type = type;
				dispatch(
					receiveCollectionById(
						Number(collection.id),
						collection,
						url,
					),
				);
			})
			.catch(err => {
				dispatch(decrementAPICount());
				dispatch(
					receiveCollectionById(
						Number(id),
						{
							apiError: {
								statusCode: err.status,
							},
						},
						url,
					),
				);
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchCollectionById =
	({
		id,
		lang,
		cityCode,
		params,
		limit = PRODUCTS_GRID_DEFAULT_COUNT,
	}: FetchCollectionInfoByIdArgs): ThunkAction<
		void,
		any,
		unknown,
		Action<string>
	> =>
	(dispatch, getState) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const limitParam = `limit=${limit}`;
		const secondaryCityParam = cityCode ? `secondary-city=${cityCode}` : '';

		const queryParams = [
			currencyParam,
			langParam,
			limitParam,
			secondaryCityParam,
		];
		const queryString = queryParams.filter(query => !!query).join('&');

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v1/collection/${id}/tour-groups?${queryString}`;

		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const { tourGroups } = json;

				const pageData = { items: tourGroups };

				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: {
							pageData,
						},
						url,
					}),
				);

				dispatch(receiveProducts({ productCards: tourGroups }));
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

/*
This API can be used to fetch collections using city, 
categoryId (city param is optional in this case), 
and subCategoryId (city param is optional in this case)
*/
export const fetchCollections =
	({
		cityCode = GLOBAL_CITY_CODE,
		lang = 'en',
		limit = 20,
		categoryId,
		subCategoryId,
		personaAffinityId,
	}: {
		cityCode?: string;
		lang?: string;
		limit?: number;
		categoryId?: string;
		subCategoryId?: string;
		personaAffinityId?: number;
	}): ThunkAction<void, any, unknown, Action<string>> =>
	(dispatch, getState) => {
		const cityCodeParam =
			cityCode && cityCode !== GLOBAL_CITY_CODE ? `city=${cityCode}` : '';
		const currentCurrencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(
			getState(),
			currentCurrencyCode,
		);
		const langParam = getApiLanguageParameter(lang);
		const limitParam = `&limit=${limit}`;
		const categoryParam = categoryId ? `&categoryId=${categoryId}` : '';
		const subCategoryParam = subCategoryId
			? `&subCategoryId=${subCategoryId}`
			: '';
		const personaParam = personaAffinityId
			? `&personaAffinityId=${personaAffinityId}`
			: '';
		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v1/collection/top/list?${cityCodeParam}${currencyParam}${langParam}${limitParam}${categoryParam}${subCategoryParam}${personaParam}`;
		if (categoryId) {
			dispatch(requestCollectionByCategoryId(cityCode, categoryId));
		} else if (subCategoryId) {
			dispatch(requestCollectionBySubcategoryId(cityCode, subCategoryId));
		} else if (personaAffinityId) {
			dispatch(requestCollectionByPersonaId(cityCode, personaAffinityId));
		} else {
			dispatch(requestCollectionByCityCode(cityCode));
		}
		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const {
					pageData: { items: collections },
					cities,
				} = json;
				const cityCodeToCityMapping = cities.reduce((acc, city) => {
					acc[city.cityCode] = city;
					return acc;
				}, {});
				const collectionIds = collections.map(({ id }) => id);
				collections.forEach(collection => {
					const { id, cityCode } = collection;
					const city = cityCodeToCityMapping[cityCode];
					const { displayName: cityDisplayName } = city;

					dispatch(
						receiveCollectionCardById(id, {
							...collection,
							cityDisplayName,
						}),
					);
				});
				if (categoryId) {
					dispatch(
						receiveCollectionByCategoryId(
							cityCode,
							categoryId,
							collectionIds,
						),
					);
				} else if (subCategoryId) {
					dispatch(
						receiveCollectionBySubcategoryId(
							cityCode,
							subCategoryId,
							collectionIds,
						),
					);
				} else if (personaAffinityId) {
					dispatch(
						receiveCollectionByPersonaId(
							cityCode,
							personaAffinityId,
							collectionIds,
						),
					);
				} else {
					dispatch(
						receiveCollectionByCityCode(
							cityCode,
							collectionIds,
							url,
						),
					);
				}
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
			});
	};

/**
 * Don't use this API for prices or localised content.
 * It is only intended to check whether the collection exists or not (and/or basic info around it)
 */
export const fetchCollectionBasicsByIds =
	({ collectionIds }: FetchCollectionBasicArgs) =>
	(dispatch, getState) => {
		const collectionIdParam =
			typeof collectionIds === 'object'
				? collectionIds.join()
				: collectionIds;
		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v1/collection?ids[]=${collectionIdParam}`;

		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const { collections } = json;
				collections.forEach(collection => {
					const { id } = collection;
					dispatch(receiveCollectionCardById(id, collection));
				});
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

/**
 * This API is used to fetch collections by city, subCategoryId and lang
 * It returns a list of collection cards
 */
export const fetchCityCollectionsBySubCategoryId =
	({
		cityCode,
		subCategoryId,
		lang = 'en',
		limit = 10,
	}: {
		cityCode: string;
		subCategoryId: string;
		lang?: string;
		limit?: number;
	}): ThunkAction<void, any, unknown, Action<string>> =>
	(dispatch, getState) => {
		const isDesktop = PlatformUtils.isDesktop();
		const platform = `&platform=${isDesktop ? 'DESKTOP' : 'WEB'}`;
		const currentCurrencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(
			getState(),
			currentCurrencyCode,
		);
		const langParam = getApiLanguageParameter(lang, true);
		const limitParam = `&limit=${limit}`;

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v3/cities/${cityCode}/subcategories/${subCategoryId}/collection-cards/?${currencyParam}${langParam}${limitParam}${platform}`;

		dispatch(
			requestCollectionsByCityIdSubCategoryId({
				cityCode,
				subCategoryId,
				lang,
				limit,
			}),
		);

		const options = getBaseRequestOptions(getState()); // TODO: check if this is required

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const {
					result: {
						collectionCards: { items: collections },
					},
				} = json;

				const mappedCollections = collections.map(collection => {
					const mappedCollection = mapNewToOldCollectionCard(
						collection,
						lang,
					);
					return mappedCollection;
				});

				dispatch(
					receiveCollectionsByCityIdSubCategoryId({
						cityCode,
						subCategoryId,
						data: mappedCollections,
					}),
				);
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
			});
	};
