import type { TOperatingDaySchedule } from 'Components/desktop/operatingHoursTable/type';

export enum ProductDescriptor {
	GUIDED_TOUR = 'GUIDED_TOUR',
	OPERATING_HOURS = 'OPERATING_HOURS',
	FREE_CANCELLATION = 'FREE_CANCELLATION',
	EXTENDED_VALIDITY = 'EXTENDED_VALIDITY',
	FLEXIBLE_DURATION = 'FLEXIBLE_DURATION',
	DURATION = 'DURATION',
	AUDIO_GUIDE = 'AUDIO_GUIDE',
	MEALS_INCLUDED = 'MEALS_INCLUDED',
	INSTANT_CONFIRMATION = 'INSTANT_CONFIRMATION',
	MOBILE_TICKET = 'MOBILE_TICKET',
	TRANSFERS = 'TRANSFERS',
	HOTEL_PICKUP = 'HOTEL_PICKUP',
}

export type TComboDaySchedule = {
	poiName: string;
	operatingDaySchedules: TOperatingDaySchedule[];
};

export type TOperatingHoursTableInfo = {
	isCombo: boolean;
	daySchedules: TOperatingDaySchedule[] | TComboDaySchedule[];
};

export type TProductDescriptor = {
	code: ProductDescriptor;
	name: string;
};
