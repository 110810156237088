import { createRef, useEffect } from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import withRedux from 'next-redux-wrapper';
import { QueryClientProvider } from '@tanstack/react-query';
import classNames from 'classnames';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'js-c... Remove this comment to see the full error message
import Cookies from 'js-cookie';
import cookie, { setCookie } from 'nookies';
/* eslint-disable-next-line no-restricted-imports */
import { StyleSheetManager } from 'styled-components';

import { ErrorCode, renderError } from 'Server/errors';
import { initializeClarity } from 'Server/initializeTrackingScripts';

import CommonHeader from 'Containers/common/commonHeader';
import Footer from 'Containers/common/footer';
import StickyBanner from 'Containers/common/stickyBanners';

import Analytics from 'Components/common/analytics';
import Conditional from 'Components/common/conditional';
import { HIDE_COOKIE_BANNER_KEY } from 'Components/common/cookieBanner';
import HasOffers from 'Components/common/hasOffers';
import Notifications from 'Components/common/notify';
import PageLoader from 'Components/common/pageLoader';
import ServiceWorker from 'Components/common/serviceWorker';

import { ProductCardProvider } from 'Contexts/productCardContext';
import {
	getLastVisitedCollectionId,
	getLastVisitedCollectionName,
	sendVariableToDataLayer,
	setCustomDimensions,
	trackEvent,
} from 'Utils/analytics';
import {
	getAnalyticsFlowType,
	hasValidLanguageCode,
	isBookingFlowPage,
	isTourGroupOpenDated,
} from 'Utils/bookingFlowUtils';
import { getWhitelistedCategoryUrl } from 'Utils/categoryUtils';
import { initClarityProjectId } from 'Utils/clarityUtils';
import { setCurrencyCookie } from 'Utils/cookieUtils';
import {
	initializeCoralogixRum,
	sendCoralogixLog,
	sendException,
	setCoralogixClientContext,
} from 'Utils/coralogix/log';
import {
	isDevelopmentEnvironment,
	isProductionEnvironment,
	isServer as isServerfromEnvUtils,
} from 'Utils/envUtils';
import { waitForEventWithTimeout } from 'Utils/eventUtils';
import { getABTestingVariantBySandboxId } from 'Utils/experiments/experimentUtils';
import {
	checkTimerBannerStrip,
	getHeightForContainerInPixels,
	getNodeFromClassName,
} from 'Utils/gen';
import { isWhitelabel } from 'Utils/hostUtils';
import initCookies from 'Utils/initialiseCookies';
import {
	getFirstValidLocale,
	getLocalizationLabels,
	initGlobalLocale,
} from 'Utils/localizationUtils';
import { read, write } from 'Utils/localStorageUtils';
import PlatformUtils from 'Utils/platformUtils';
import { initializeDynamicViewPorts } from 'Utils/polyfills/dynamicViewPort';
import { hasMultipleAvailableVariants } from 'Utils/productUtils';
import { queryClient } from 'Utils/queryClient';
import { redirectTo } from 'Utils/redirectUtils';
import {
	getAPIServerError,
	getCategoriesInfoById,
	getCollectionById,
	getCurrencyCodesList,
	getCurrentCurrency,
	getCurrentPage,
	getProduct,
	getSubCategoriesInfoById,
	getUser,
} from 'Utils/stateUtils';
import {
	doesCurrentRouteIncludeString,
	getCurrencyFromUrl,
	getLanguageFromUrl,
	getLocationObjectFromRouter,
	getNakedDomain,
	getQueryObject,
	getSourceFromUrl,
	isHomePageUrl,
	removeMultiplePageQueries,
	saveAffiliateParamsToCookies,
} from 'Utils/urlUtils';
import { isSignedIn } from 'Utils/userUtils';

import { configureStore } from 'Store/configureStore';
import { fetchCities } from 'Thunks/city';
import { fetchCurrencies } from 'Thunks/currency';
import { getDomainConfig } from 'Thunks/domain';
import { fetchLanguages } from 'Thunks/language';
import { fetchUserDetails } from 'Thunks/user';
import { fetchUserGeoLocation } from 'Thunks/userGeoLocation';
import { changeCity, trimCities } from 'Actions/city';
import { changeCurrency } from 'Actions/currency';
import { setUserAgent } from 'Actions/device';
import { changeLanguage } from 'Actions/language';
import { changePage } from 'Actions/page';
import { setPromoDetails } from 'Actions/promo';
import { setServerPageStatus } from 'Actions/serverStatus';
import { changeUserProfileType, setSandboxID } from 'Actions/user';
import { ECollectionType } from 'ReduxTypes/collection';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	ACTIVE_LANGUAGE_CODES,
	ANALYTICS_FLOW_TYPE,
	ANALYTICS_PLATFORM,
	BARCELONA_PASS_URL,
	BOOKING_FLOW_STAGE,
	BOOKING_FLOW_TOP_LEVEL_ROUTES,
	BOOKING_FLOW_TYPE,
	COLLECTION_TYPE,
	COOKIE,
	EVENTS,
	GLOBAL_CITY_CODE,
	HEADER,
	LOG_CATEGORIES,
	LOG_LEVELS,
	LOGIN_EVENT,
	LOGIN_OPTION,
	LTD_COLLECTION_ID,
	PAGE_TYPE,
	QUERY_PARAM,
	RTL_LANGUAGE_CODES,
	SEO_INDEXABLE_LANGUAGE_CODES,
	SEO_NON_INDEXABLE_LANGUAGE_CODES,
	TGID_INDEPENDENT_BOOKING_FLOW_STAGES,
	TIME,
	UserProfileTypes,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import { AccessibilityGlobalStyle } from 'Static/globalStyles/accessibility';
import { AquaGlobalStyleObject } from 'Static/globalStyles/aqua';
import { GlobalFocusStyle } from 'Static/globalStyles/focus';

import '../styles/globals.css';

const ArabicGlobalStyle = dynamic(() =>
	import('Static/globalStyles/arabic').then(
		module => module.ArabicGlobalStyle,
	),
);
const ReactSelectStyles = dynamic(() =>
	import('Static/globalStyles/reactSelect').then(
		module => module.ReactSelectStyles,
	),
);

const ConsentBanner = dynamic(
	() =>
		import(
			/* webpackChunkName: 'ConsentBanner' */ 'Components/common/consent'
		),
	{ ssr: false },
);
const LoginModal = dynamic(() => import('Containers/common/loginModal'), {
	ssr: false,
});
const BiLinkBanner = dynamic(() => import('Containers/common/biLinkBanner'), {
	ssr: false,
});
const BiLinkPopup = dynamic(() => import('Containers/common/biLinkPopup'), {
	ssr: false,
});
const GoogleOneTapSignin = dynamic(
	() => import('Containers/common/googleOneTapSignin'),
	{ ssr: false },
);

const SiftAnalytics = dynamic(() => import('Components/common/siftAnalytics'), {
	ssr: false,
});
const ZendeskChat = dynamic(() => import('Components/common/zendeskChat'), {
	ssr: false,
});
const Error = dynamic(() => import('pages/_error'));
const NotificationToast = dynamic(
	() => import('Components/common/headoutToast'),
	{ ssr: false },
);
const WhyHeadoutStrip = dynamic(
	() => import('Components/desktop/whyHeadoutStrip'),
);

const Clarity = () => {
	useEffect(() => {
		let clarityProjectId = Cookies.get(COOKIE.CLARITY_PROJECT_ID);
		if (!clarityProjectId?.length) {
			// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
			clarityProjectId = initClarityProjectId();
		}

		if (isProductionEnvironment()) {
			initializeClarity(clarityProjectId);
		}
	}, []);

	return null;
};

const HSID_VAR = 'h-sid';
const HeadoutSessionIdSetterComponent = () => {
	const validHsidFromCookie = Cookies.get(HSID_VAR);
	if (validHsidFromCookie) {
		return null;
	}

	const hsidProviderSrc = `${process.env.NEXT_PUBLIC_BASE_URL}/hsid-provider.html`;

	return (
		<iframe
			width='0'
			height='0'
			tabIndex={-1}
			title='HSID provider'
			className='hidden'
			src={hsidProviderSrc}
		/>
	);
};

type Props = {
	store: any;
	cookies: any;
};

class AppElements extends App<Props> {
	static getInitialProps: any;

	constructor(props: any) {
		super(props);

		const { lang, host, asPath } = props;

		if (!isServerfromEnvUtils()) {
			window.addEventListener('error', err => {
				trackEvent({
					event: 'Javascript Error',
					Domain: host,
					Path: asPath,
					'Error Message': err.message,
					'File Name': err.filename,
					'Error Stack': err.error?.stack + '',
				});

				sendException({
					exception: 'JavaScript Error',
					severity: LOG_LEVELS.ERROR as any,
					methodName: 'App_constructor',
					metaData: err,
					category: LOG_CATEGORIES.CLIENT_EXCEPTION,
				});

				return false;
			});
		}

		strings.setContent({
			default: props.localizedStrings,
		});

		if (RTL_LANGUAGE_CODES.includes(lang)) {
			this.rtlPlugin = require('stylis-plugin-rtl').default;
		}

		const { cookies, isBot, store } = props;
		const state = store.getState();
		this.setCustomDimension(state);
		const hsidReady = !!cookies[COOKIE.SANDBOX_ID] || isBot;
		this.state = {
			...this.state,
			hsidReady,
		};
		this.appMountTime = new Date().getTime();
	}

	appMountTime = 0;
	rtlPlugin = null;

	state = {
		showTimerPopUp: false,
		pageLoaded: true,
		hsidReady: false,
		showCookieBanner: false,
	};

	changeCookieBannerState = (state = false) => {
		this.setState({ ...this.state, showCookieBanner: state });
	};

	// @ts-expect-error TS(7031): Binding element 'hsid' implicitly has an 'any' typ... Remove this comment to see the full error message
	onHSIDReady({ hsid }) {
		const { store } = this.props;
		const hsidResolutionTime = new Date().getTime() - this.appMountTime;
		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.HSID_LOAD_TIME,
			value: hsidResolutionTime,
		});

		if (hsid) {
			const state = store.getState();
			this.setCustomDimension(state);
		}
		store.dispatch(setSandboxID({ hsid }));
		this.setState({ hsidReady: true });
	}

	componentMountedRouteStartHandler = (
		_url: string,
		{
			shallow,
		}: {
			shallow: boolean;
		},
	) => {
		if (!shallow) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			this.setState({ pageLoaded: false });
		}
	};

	componentMountedRouteStopHandler = () => {
		this.setState({ pageLoaded: true });
	};
	routeChangeErrorHandler = (error: any) => {
		const flowType = getProduct(
			this.props.store.getState(),
			Router.query.id,
		)?.flowType;

		if (
			error?.cancelled &&
			flowType === BOOKING_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFER
		) {
			this.setState({ pageLoaded: true });
		}
	};
	componentDidMount() {
		// @ts-expect-error TS(2339): Property 'store' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const { store, lang, isCookieBannerShown, experiments } = this.props;
		const { hostname } = location;
		initializeDynamicViewPorts({ isMobile: !PlatformUtils.isDesktop() });
		initializeCoralogixRum();
		store.dispatch(fetchUserGeoLocation());
		const cookies = Cookies.get();
		const state = store.getState();
		const userAlreadyInStore = state?.user?.user?.customerId;
		if (!userAlreadyInStore && cookies[COOKIE.IS_SIGNED_IN]?.length) {
			// only fetch if user is not in store
			store.dispatch(fetchUserDetails({ cookies }));
		}
		if (cookies?.currentCurrency)
			setCurrencyCookie(cookies.currentCurrency);

		const cookieHSID = cookies[COOKIE.SANDBOX_ID];
		const experimentOverride =
			getQueryObject(location)?.[COOKIE.EXPERIMENT_OVERRIDE];
		const nakedDomain = getNakedDomain(hostname);
		if (cookieHSID) {
			store.dispatch(setSandboxID({ hsid: cookieHSID }));
			setCoralogixClientContext({ hsid: cookieHSID });
			this.onHSIDReady({ hsid: cookieHSID });
			// experiments getting resolved on server so tagging them here for sentry
			(experiments || []).forEach((experiment: string) => {
				getABTestingVariantBySandboxId(experiment, cookieHSID, true);
			});
		}

		if (experimentOverride && typeof experimentOverride === 'string') {
			setCookie(null, COOKIE.EXPERIMENT_OVERRIDE, experimentOverride, {
				domain: nakedDomain,
				path: '/',
				httpOnly: false,
				maxAge: TIME.IN_MINUTES,
			});
		}

		// Track Success Login for Magic Link.
		if (cookies[COOKIE.LOGIN_SUCCESS_TRACKER]?.length) {
			trackEvent({
				eventName: LOGIN_EVENT.SUCCESSFUL,
				source: LOGIN_OPTION.EMAIL,
			});
			// destroy cookie once tracked.
			Cookies.remove(COOKIE.LOGIN_SUCCESS_TRACKER);
		}

		this.setState({ pageLoaded: true });
		Router.events.on(
			'routeChangeStart',
			this.componentMountedRouteStartHandler,
		);
		Router.events.on(
			'routeChangeComplete',
			this.componentMountedRouteStopHandler,
		);
		Router.events.on('routeChangeError', this.routeChangeErrorHandler);
		saveAffiliateParamsToCookies();
		removeMultiplePageQueries([
			// @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
			QUERY_PARAM.CURRENCY_CODE,
			// @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
			QUERY_PARAM.LANGUAGE_CODE,
		]);
		initGlobalLocale({
			lang,
			cookies,
		});
		const onMessageReceived = (e: any) => {
			const { origin, data } = e;
			if (origin !== process.env.NEXT_PUBLIC_BASE_URL) {
				return;
			}

			try {
				const { hsid } =
					typeof data === 'object' ? data : JSON.parse(data);
				const { hostname } = location;
				if (hsid) {
					const nakedDomain = getNakedDomain(hostname);

					setCookie(null, COOKIE.SANDBOX_ID, hsid, {
						domain: nakedDomain,
						path: '/',
						httpOnly: false,
						maxAge: TIME.SECONDS_IN_YEARS,
					});
				}
				this.onHSIDReady({
					hsid: hsid || null,
				});
			} catch (e) {
				this.onHSIDReady({ hsid: null });
			}
		};
		const onMessageTimeout = () => {
			this.onHSIDReady({ hsid: null });
		};
		// the correct hsid-provider data will be a stringified JSON and will have hsid
		// all other message events will be invalidated by this Fn.
		// @ts-expect-error TS(7031): Binding element 'origin' implicitly has an 'any' t... Remove this comment to see the full error message
		const validateMessage = ({ origin, data = {} }) =>
			origin === process.env.NEXT_PUBLIC_BASE_URL &&
			typeof data !== 'object' &&
			// @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
			JSON.parse(data)?.hsid;

		if (!cookieHSID)
			waitForEventWithTimeout({
				emitter: window,
				eventName: EVENTS.WINDOW.MESSAGE,
				timeout: 5000,
				// @ts-expect-error TS(2322): Type '({ origin, data }: { origin: any; data?: {} ... Remove this comment to see the full error message
				validatorFn: validateMessage,
			}).then(onMessageReceived, onMessageTimeout);

		if (isCookieBannerShown) {
			this.changeCookieBannerState(!read(HIDE_COOKIE_BANNER_KEY, false));
			write(HIDE_COOKIE_BANNER_KEY, true);
		}
	}

	componentDidUpdate() {
		// @ts-expect-error TS(2339): Property 'lang' does not exist on type 'Readonly<A... Remove this comment to see the full error message
		const { lang } = this.props;
		const cookies = Cookies.get();
		initGlobalLocale({
			lang,
			cookies,
		});
		this.setCustomDimension();
	}

	componentWillUnmount() {
		Router.events.off(
			'routeChangeStart',
			this.componentMountedRouteStartHandler,
		);
		Router.events.off(
			'routeChangeComplete',
			this.componentMountedRouteStopHandler,
		);
		Router.events.off('routeChangeError', this.routeChangeErrorHandler);
	}

	getPageSpecificProperties = (state: any) => {
		const { page } = state;
		// @ts-expect-error TS(2339): Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
		const { location } = this.props;
		const { query } = location;
		const { currentPage } = page ?? {};
		const { id } = query ?? {};
		switch (currentPage) {
			case PAGE_TYPE.EXPERIENCE:
			case PAGE_TYPE.CHECKOUT:
			case PAGE_TYPE.SELECT:
			case PAGE_TYPE.BOOKING:
				const product = getProduct(state, id);
				const isOpenDated = isTourGroupOpenDated(product);
				const isMultiVariant = hasMultipleAvailableVariants(product);
				let flowType;
				if (isOpenDated) {
					flowType = isMultiVariant
						? ANALYTICS_FLOW_TYPE.OPEN_DATED_MULTI_VARIANT
						: ANALYTICS_FLOW_TYPE.OPEN_DATED_NO_VARIANT;
				} else {
					flowType = product ? getAnalyticsFlowType(product) : null;
				}
				const {
					name,
					primaryCollection,
					primaryCategory,
					primarySubCategory,
					averageRating,
					reviewCount,
				} = product ?? {};

				return {
					tgid: id,
					experienceName: name,
					collectionId: primaryCollection?.id,
					collectionName: primaryCollection?.displayName,
					categoryId: primaryCategory?.id,
					categoryName: primaryCategory?.name,
					subCategoryId: primarySubCategory?.id,
					subCategoryName: primarySubCategory?.name,
					flowType,
					averageRating,
					reviewCount,
				};
			case PAGE_TYPE.COLLECTION:
				return {
					collectionId: id,
					collectionName: getCollectionById(state, id)?.displayName,
					layoutType:
						getCollectionById(state, id)?.type ===
						ECollectionType.DAY_TRIP
							? COLLECTION_TYPE.DAY_TRIPS
							: COLLECTION_TYPE.POI,
				};
			case PAGE_TYPE.CATEGORY: {
				const cityCode =
					state?.city?.currentCityCode || GLOBAL_CITY_CODE;
				return {
					categoryId: id,
					categoryName: getCategoriesInfoById(state, id, cityCode)
						?.name,
				};
			}
			case PAGE_TYPE.SUB_CATEGORY: {
				const cityCode =
					state?.city?.currentCityCode || GLOBAL_CITY_CODE;
				return {
					subCategoryId: id,
					subCategoryName: getSubCategoriesInfoById(
						state,
						id,
						cityCode,
					)?.name,
				};
			}
			default:
				return null;
		}
	};

	getCustomProperties = (preLoadedState: any) => {
		if (preLoadedState) {
			return {
				city: preLoadedState?.city,
				backendExperimentMap: preLoadedState?.experimentVariantMap,
				currentPage: preLoadedState?.page?.currentPage,
				currencyCode: getCurrentCurrency(preLoadedState),
				user: getUser(preLoadedState),
			};
		}
		const { store } = this.props;
		const state = store.getState();
		return {
			city: state?.city,
			backendExperimentMap: state?.experimentVariantMap,
			currentPage: state?.page?.currentPage,
			currencyCode: getCurrentCurrency(state),
			user: getUser(state),
		};
	};

	setCustomDimension = (state = null) => {
		const {
			user,
			city: { currentCityCode, citiesMap },
			currentPage,
			currencyCode,
		} = this.getCustomProperties(state);
		const { store } = this.props;
		const pageSpecificProperties = this.getPageSpecificProperties(
			state ?? store.getState(),
		);
		const country = citiesMap[currentCityCode]?.country;
		const { displayName: countryDisplayName, currency } = country ?? {};
		const {
			// @ts-expect-error TS(2339): Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
			location: { query, asPath },
			// @ts-expect-error TS(2339): Property 'host' does not exist on type 'Readonly<A... Remove this comment to see the full error message
			host,
			cookies: serverCookies,
		} = this.props;
		const { lang, id } = query;
		const { code: defaultCityCurrencyCode } = currency ?? {};
		const cookies = isServerfromEnvUtils() ? serverCookies : Cookies.get();
		setCustomDimensions({
			platform: PlatformUtils.isDesktop()
				? ANALYTICS_PLATFORM.DESKTOP
				: ANALYTICS_PLATFORM.MOBILE,
			isSignedIn: !!user,
			hsId: cookies[COOKIE.SANDBOX_ID],
			host,
			path: asPath,
			currentCity: currentCityCode,
			currentPageType: currentPage,
			currentLanguage: lang ? lang.toUpperCase() : 'EN',
			pageId: (query && id) || null,
			lastVisitedCollectionId: getLastVisitedCollectionId(),
			lastVisitedCollectionName: getLastVisitedCollectionName(),
			currencyCode: currencyCode ?? defaultCityCurrencyCode,
			countryDisplayName,
			...pageSpecificProperties,
		});
	};

	selfRef = createRef();

	setShowTimerPopUp = (value: any) => {
		this.setState({
			showTimerPopUp: value,
		});
	};

	setPaddingForMainContainer = () => {
		if (isServerfromEnvUtils()) {
			return;
		}
		const elem = this.selfRef.current;
		const mobileHeaderHeight = getHeightForContainerInPixels(
			getNodeFromClassName('mobile-header') ||
				getNodeFromClassName('booking-flow-header'),
		);
		if (elem) {
			(elem as any).style.paddingTop = `${
				mobileHeaderHeight +
				(checkTimerBannerStrip()
					? getHeightForContainerInPixels(
							getNodeFromClassName('timer-banner'),
					  )
					: 0)
			}px`;
		}
	};

	shouldShowStickyHeaderFooter = (location: any) =>
		getSourceFromUrl(location) !== 'ios_app' &&
		getSourceFromUrl(location) !== 'android_app';

	getLanguageBasedGlobalStyling = (lang: any) => {
		switch (lang) {
			case 'ar':
				return <ArabicGlobalStyle />;
			default:
				return null;
		}
	};

	render() {
		const {
			// @ts-expect-error TS(2339): Property 'currentPage' does not exist on type 'Rea... Remove this comment to see the full error message
			currentPage,
			Component,
			pageProps,
			store,
			// @ts-expect-error TS(2339): Property 'deviceType' does not exist on type 'Read... Remove this comment to see the full error message
			deviceType,
			// @ts-expect-error TS(2339): Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
			location,
			cookies,
			// @ts-expect-error TS(2339): Property 'host' does not exist on type 'Readonly<A... Remove this comment to see the full error message
			host,
			// @ts-expect-error TS(2339): Property 'serverStatusCode' does not exist on type... Remove this comment to see the full error message
			serverStatusCode,
			// @ts-expect-error TS(2339): Property 'isBot' does not exist on type 'Readonly<... Remove this comment to see the full error message
			isBot,
			// @ts-expect-error TS(2339): Property 'err' does not exist on type 'Readonly<Ap... Remove this comment to see the full error message
			err,
		} = this.props;
		const showComponent = !serverStatusCode;
		const { showTimerPopUp, pageLoaded, hsidReady, showCookieBanner } =
			this.state;
		const { query } = location;
		const { lang = 'en' } = query;
		const isHelpPage = doesCurrentRouteIncludeString('/help', location);
		const isBookingPage = doesCurrentRouteIncludeString('/book/', location);
		const isConfirmationPage = doesCurrentRouteIncludeString(
			'/confirmation',
			location,
		);
		const isManageBookingPage = doesCurrentRouteIncludeString(
			'/manage-booking',
			location,
		);
		const isPaymentProofPage = doesCurrentRouteIncludeString(
			'/payment/payment-proof',
			location,
		);
		const isAppTicketsPage = doesCurrentRouteIncludeString(
			'/apps',
			location,
		);
		const isVoucherPage = doesCurrentRouteIncludeString(
			'/voucher',
			location,
		);
		const isPrivacyPolicyPage = doesCurrentRouteIncludeString(
			'/privacy-policy',
			location,
		);
		const isSeatmapSelectPage =
			doesCurrentRouteIncludeString(
				BOOKING_FLOW_STAGE.SEATMAP_SELECT,
				location,
			) ||
			doesCurrentRouteIncludeString(
				BOOKING_FLOW_STAGE.SEATMAP_VARIANT,
				location,
			);
		const isHomePage = isHomePageUrl(location.pathname);

		const isExternalSeatmap = doesCurrentRouteIncludeString(
			BOOKING_FLOW_STAGE.EXTERNAL_SEATMAP_SELECT,
			location,
		);

		const isSupplyPartnerPage = doesCurrentRouteIncludeString(
			'supply-partner',
			location,
		);
		const isBiLinkGeneratorPage = doesCurrentRouteIncludeString(
			'bilink-generator',
			location,
		);
		const isCheckoutPage = doesCurrentRouteIncludeString(
			'checkout',
			location,
		);
		const isRecommendationsPage = doesCurrentRouteIncludeString(
			'/recommendations',
			location,
		);

		// to hide footer
		const hideCommonFooter =
			isPaymentProofPage ||
			isVoucherPage ||
			isSeatmapSelectPage ||
			isExternalSeatmap ||
			isCheckoutPage ||
			isRecommendationsPage;

		const hideWhyHeadoutStrip = isAppTicketsPage;

		// to hide Header
		const hideCommonHeader =
			isVoucherPage || isRecommendationsPage || isCheckoutPage;

		const isDev = isDevelopmentEnvironment();

		const shouldShowCookieBanner =
			showCookieBanner && !isPrivacyPolicyPage && !isBiLinkGeneratorPage;

		const isZendeskChatEnabled =
			!isBookingFlowPage({ currentPage, location }) &&
			!isSupplyPartnerPage &&
			!isHelpPage &&
			!isManageBookingPage &&
			!isConfirmationPage &&
			!isAppTicketsPage &&
			!shouldShowCookieBanner;

		const headerClassDesktop = classNames({
			'content-main': !isPaymentProofPage,
		});
		const headerClassMobile = classNames({
			'content-main': !isHomePage,
			'no-padding': true,
			'bg-white': isHelpPage,
		});

		return (
			<StyleSheetManager
				stylisPlugins={this.rtlPlugin ? [this.rtlPlugin] : []}
			>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						{this.getLanguageBasedGlobalStyling(lang)}
						{isCheckoutPage && <ReactSelectStyles />}
						<AccessibilityGlobalStyle />
						<GlobalFocusStyle />
						<AquaGlobalStyleObject />
						<Analytics host={host} />
						<ProductCardProvider>
							<Conditional if={hsidReady}>
								<SiftAnalytics cookies={cookies} />
							</Conditional>
							<Conditional
								if={!isSignedIn(cookies) && !isBookingPage}
							>
								<GoogleOneTapSignin />
							</Conditional>
							{!pageLoaded && <PageLoader />}
							<Notifications key='notifications' />
							<NotificationToast />
							<Conditional
								if={!isBiLinkGeneratorPage && pageLoaded}
							>
								<BiLinkBanner
									// @ts-expect-error
									setShowTimerPopUp={this.setShowTimerPopUp}
									location={location}
									isDesktop={PlatformUtils.isDesktop()}
								/>
							</Conditional>
							<Conditional if={pageLoaded && !hideCommonHeader}>
								<CommonHeader currentPage={currentPage} />
							</Conditional>

							<ZendeskChat
								isBot={isBot}
								location={location}
								isZendeskChatEnabled={isZendeskChatEnabled}
							/>
							{/* @ts-expect-error TS(2786): 'StickyBanner' cannot be used as a JSX component. */}
							<StickyBanner location={location} />

							<Conditional if={showComponent && pageLoaded}>
								<main
									key='content-main'
									className={
										PlatformUtils.isDesktop()
											? headerClassDesktop
											: headerClassMobile
									}
									// @ts-expect-error TS(2322): Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message
									ref={this.selfRef}
								>
									<Component
										{...pageProps}
										deviceType={deviceType}
									/>
								</main>
							</Conditional>

							<Conditional
								if={!showComponent && serverStatusCode}
							>
								<Error
									statusCode={serverStatusCode}
									location={location}
									query={location?.query}
									err={err}
								/>
							</Conditional>
							<Conditional if={!isBiLinkGeneratorPage}>
								<BiLinkPopup
									showTimerPopUp={showTimerPopUp}
									setShowTimerPopUp={this.setShowTimerPopUp}
								/>
							</Conditional>

							<HasOffers key='has-offers' />
							<Conditional if={pageLoaded && !hideCommonFooter}>
								<Conditional if={!hideWhyHeadoutStrip}>
									<WhyHeadoutStrip key='whyHeadoutStrip' />
								</Conditional>
								<Footer />
							</Conditional>
							<ServiceWorker key='service-worker' />
							<LoginModal location={location} />
						</ProductCardProvider>
						<Conditional if={typeof window !== 'undefined'}>
							<HeadoutSessionIdSetterComponent />
						</Conditional>
						<div id='modal-root' />
						<Clarity />
						<Conditional if={!isDev}>
							<ConsentBanner isPageLoaded={pageLoaded} />
						</Conditional>
					</Provider>
				</QueryClientProvider>
			</StyleSheetManager>
		);
	}
}

AppElements.getInitialProps = async ({ Component, ctx }: any) => {
	const { query, store, req, res, pathname, asPath } = ctx;
	const isServer = !!req;
	const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
	const isUABot = req
		? req.headers['x-bot'] === 'true'
		: PlatformUtils.isBot(userAgent);
	const cookies = req ? cookie.get(ctx) : cookie.get();
	const { cookieBanner } = query;
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	const cookieLang = cookies[COOKIE.CONTENT_LANG];
	const paramLang = query.lang;
	const isRewriteFallbackLang = query.isRWFallbackLang;
	const languageFromUrl = getLanguageFromUrl(location);
	const cookieHSID = cookies[COOKIE.SANDBOX_ID];
	const { host } = req ? req.headers : window.location;

	if (cookieHSID) {
		store.dispatch(setSandboxID({ hsid: cookieHSID }));
	}

	if (query?.amp) {
		return redirectTo(res, location.asPath.replace('?amp=1', ''), 301);
	}

	// TTD Promo Handler
	const ttdProductId = query?.product_id;
	if (ttdProductId) {
		const activeTTDTgid = pathname.includes('/tour')
			? ttdProductId || query?.id
			: null;
		const ttdTgidCookie = cookies[COOKIE.TTD_TGIDS];
		const currentTgids = ttdTgidCookie ? ttdTgidCookie.split(',') : [];

		if (activeTTDTgid && !currentTgids.includes(activeTTDTgid)) {
			currentTgids.push(activeTTDTgid);
			setCookie(ctx, COOKIE.TTD_TGIDS, currentTgids.join(','), {
				expires: new Date(Date.now() + TIME.IN_DAYS * 30),
				path: '/',
			});
		}
		cookies[COOKIE.TTD_TGIDS] = currentTgids.join(',');
	}
	if (cookies[COOKIE.TTD_TGIDS]) {
		const ttdTgidCookie = cookies[COOKIE.TTD_TGIDS];
		const currentTgids = ttdTgidCookie ? ttdTgidCookie.split(',') : [];
		store.dispatch(
			setPromoDetails({ ttd: { tourGroupIds: currentTgids } }),
		);
	}

	if (location.asPath.includes(`/audio-guide/deeplink`)) {
		return redirectTo(res, `/audio-guide`, 301);
	}

	if (hasValidLanguageCode(languageFromUrl, paramLang)) {
		let [asPath, queryParam] = location.asPath?.split('?') ?? [];
		if (queryParam) {
			let queryParamObj = new URLSearchParams(queryParam);
			queryParamObj.delete(QUERY_PARAM.LANGUAGE_CODE);
			queryParam = queryParamObj.toString()
				? `?${queryParamObj.toString()}`
				: '';
		}

		store.dispatch(
			changeLanguage({
				code: languageFromUrl,
				context: ctx,
			}),
		);
		const updatedPath = `/${languageFromUrl}/${asPath.replace(
			`/${paramLang}/`,
			'',
		)}${queryParam}`;

		return redirectTo(res, updatedPath);
	} else if (paramLang) {
		if (
			!isRewriteFallbackLang &&
			ACTIVE_LANGUAGE_CODES.includes(paramLang)
		) {
			store.dispatch(
				changeLanguage({
					code: paramLang,
					context: ctx,
				}),
			);
			// causing problems in dummy voucher so added an exception below
			if (paramLang === 'en' && !location.pathname.includes('/voucher')) {
				return redirectTo(
					res,
					`${location.asPath.replace(`/${paramLang}/`, '/')}`,
				);
			}
			// todo - revert once voucher BE starts supporting ar, pl, ko, id langs
			if (
				location.pathname.includes('/voucher') &&
				!SEO_INDEXABLE_LANGUAGE_CODES.includes(paramLang)
			) {
				return redirectTo(
					res,
					`${location.asPath.replace(`/${paramLang}/`, '/en/')}`,
				);
			}
		} else if (
			cookieLang &&
			ACTIVE_LANGUAGE_CODES.includes(cookieLang) &&
			cookieLang !== 'en'
		) {
			store.dispatch(
				changeLanguage({
					code: cookieLang,
					context: ctx,
				}),
			);
			return redirectTo(
				res,
				`/${cookieLang}${location.asPath.replace(`/${paramLang}`, '')}`,
			);
		} else {
			// show 404 if paramLang is invalid.
			if (!ACTIVE_LANGUAGE_CODES.includes(paramLang))
				renderError(res, 404);
		}
	} else if (
		cookieLang &&
		ACTIVE_LANGUAGE_CODES.includes(cookieLang) &&
		cookieLang !== 'en' &&
		!location?.asPath?.includes?.(`/${cookieLang}/`) // avoid infinite locale redirects on 404 (query is empty in such cases)
	) {
		store.dispatch(
			changeLanguage({
				code: cookieLang,
				context: ctx,
			}),
		);
		return redirectTo(res, `/${cookieLang}${location.asPath}`);
	}

	if (isWhitelabel({ host })) {
		if (
			!BOOKING_FLOW_TOP_LEVEL_ROUTES.some(route =>
				pathname?.includes(`/${route}/`),
			) &&
			!TGID_INDEPENDENT_BOOKING_FLOW_STAGES.some(route =>
				pathname?.includes(`/${route}`),
			)
		) {
			if (host.startsWith('book.')) {
				return redirectTo(
					res,
					`https://${host.replace('book.', 'www.')}`,
				);
			}
			if (host.startsWith('barcelonapass.')) {
				return redirectTo(res, BARCELONA_PASS_URL);
			}
		}
	}

	// lang set in url slug takes precedence over cookie value
	// as cookie gets set *after* the user has manually changed language
	// which happens via url slug
	const lang = getFirstValidLocale([paramLang, cookieLang, 'en']);
	let localizedStrings = null;

	const assignLocalizationLabels = async () => {
		localizedStrings = await getLocalizationLabels({ lang });
	};

	if (isServer) {
		try {
			await initCookies(req, res, ctx);
		} catch (e) {
			//
		}

		const currencyFromUrl = getCurrencyFromUrl(location);
		const cookieCurrency = cookies[COOKIE.CURRENT_CURRENCY];
		const countryCode =
			req.headers[HEADER.CLOUDFRONT_COUNTRY_CODE] || query.countryCode;

		// Set the correct user-agent/platform on the server. For PlatformUtils.isDesktop() to work correctly
		const deviceType = PlatformUtils.cdnDetectDevice(req);

		await Promise.all([
			store.dispatch(getDomainConfig(`https://${host}`)),
			store.dispatch(fetchCities()),
			store.dispatch(
				fetchCurrencies({
					cookieCurrency,
					countryCode,
					currencyFromUrl,
					skipLocationBased: isUABot,
					host,
				}),
			),
			store.dispatch(fetchLanguages()),
			assignLocalizationLabels(),
		]);

		const currencyCodesList = getCurrencyCodesList(store.getState());
		const currencyCode =
			(currencyCodesList.includes(currencyFromUrl) && currencyFromUrl) ||
			cookieCurrency;

		if (currencyCodesList.includes(currencyCode)) {
			store.dispatch(
				changeCurrency({
					currencyCode,
					context: ctx,
				}),
			);
		}

		const cookieCityCode = cookies?.[COOKIE.CURRENT_CITY_CODE];
		if (
			cookieCityCode &&
			cookieCityCode !== 'undefined' &&
			cookieCityCode !== 'null'
		) {
			store.dispatch(
				changeCity({
					cityCode: cookieCityCode,
					context: ctx,
				}),
			);
		}

		store.dispatch(
			changeUserProfileType({
				userProfileType:
					cookies[COOKIE.USER_PROFILE_TYPE] ||
					UserProfileTypes.ANONYMOUS,
			}),
		);
		store.dispatch(
			setUserAgent({
				userAgent,
				host,
				deviceType,
				countryCode,
				isUABot,
			}),
		);
	}

	const promiseList = [];

	if (!localizedStrings) {
		promiseList.push(assignLocalizationLabels());
	}
	if (!isServer) {
		promiseList.push(store.dispatch(fetchCities()));
	}
	await Promise.all(promiseList);

	const pageProps = Component.getInitialProps
		? await Component.getInitialProps({
				...ctx,
				localizedStrings,
		  })
		: {};

	const state = store.getState();
	const currentPage = getCurrentPage(state);

	// Trimming cities on server for all pages except cities sitemap
	if (isServer && currentPage !== PAGE_TYPE.CITIES_SITEMAP) {
		const retainCities = [
			PAGE_TYPE.HOME,
			PAGE_TYPE.ACCOUNT,
			PAGE_TYPE.ERROR,
		].some(page => page === currentPage);
		store.dispatch(
			trimCities({
				retainCityCount: retainCities ? 30 : 0,
			}),
		);
	}

	const url = req ? req.url : window?.location.href;
	const pageStatusCode = res ? res.statusCode : null;
	const APIServerStatus = getAPIServerError(state);
	const APIServerStatusCode = APIServerStatus.statusCode;
	const serverStatusCode = ErrorCode(pageStatusCode, APIServerStatusCode);

	await store.dispatch(setServerPageStatus(url, pageStatusCode));

	if (res && serverStatusCode) {
		res.statusCode = serverStatusCode;
	}
	if (serverStatusCode) {
		store.dispatch(changePage(PAGE_TYPE.ERROR));
	}

	const isNoIndexPage =
		pageProps?.helmetFunctions?.isNoIndex ||
		SEO_NON_INDEXABLE_LANGUAGE_CODES.includes(lang);
	const transformedPageProps = Object.assign(
		{},
		{
			...pageProps,
			helmetFunctions: {
				...pageProps?.helmetFunctions,
				isNoIndex: isNoIndexPage,
			},
		},
	);

	const experiments = pageProps?.experiments ?? [];

	initGlobalLocale({ lang, cookies });

	//Redirects for evanevans.headout.com and other affiliate specific sub-domains
	const whitelistedCategoryUrl = getWhitelistedCategoryUrl({ host });
	if (whitelistedCategoryUrl) {
		switch (currentPage) {
			case PAGE_TYPE.EXPERIENCE:
				const { primaryCollection } =
					getProduct(state, query?.id) ?? {};
				if (primaryCollection?.id !== LTD_COLLECTION_ID) {
					return redirectTo(res, whitelistedCategoryUrl, 301);
				}
				break;
			case PAGE_TYPE.COLLECTION:
				if (Number(query?.id) !== LTD_COLLECTION_ID) {
					return redirectTo(res, whitelistedCategoryUrl, 301);
				}
				break;
			case PAGE_TYPE.CITY:
			case PAGE_TYPE.HOME:
			case PAGE_TYPE.CATEGORY:
			case PAGE_TYPE.SUB_CATEGORY:
				return redirectTo(res, whitelistedCategoryUrl, 301);
		}
	}

	return {
		lang,
		pageProps: transformedPageProps,
		cookies,
		currentPage,
		isServer,
		location,
		serverStatusCode,
		host,
		localizedStrings,
		isBot: isUABot,
		isCookieBannerShown: cookieBanner !== 'false',
		err: ctx.err,
		experiments,
	};
};

export default withRedux(configureStore)(AppElements);
