import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import tokens from '@headout/aer/src/tokens/index';
import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import AcceptedMethods from 'Containers/common/acceptedPayments';
import Logo from 'Containers/common/logo';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import LH2 from 'Components/common/localizedTags/localizedHeading2';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import SocialMedia from 'Components/common/socialMedia';

import { MailIcon, MessageIcon, PhoneIcon } from 'Assets/svg/contact';
import { HelpIcon } from 'Assets/svg/header/HelpIcon';

import { getWhitelistedCategoryUrl } from 'Utils/categoryUtils';
import PlatformUtils from 'Utils/platformUtils';
import {
	getCurrentCityCode,
	getCurrentLanguageCode,
	getCurrentPage,
	getDiscoverableCitiesCount,
	getDiscoverableCityCodes,
	getDomainConfig,
	getDomainLogoConfig,
	getHost,
	getUserLandingUrl,
} from 'Utils/stateUtils';
import {
	doesCurrentRouteIncludeString,
	getLocationObjectFromRouter,
	getSourceFromUrl,
	isCollectionUrl,
} from 'Utils/urlUtils';
import { openZendeskChatWidget } from 'Utils/zenchatUtils';

import {
	AFFILIATES_PORTAL_LINK,
	BLOG_LINK,
	DOWNLOAD_APP_QR,
	DOWNLOAD_APP_QR_DIM,
	FOOTER_CONTACT_ICON_DIM,
	HEADOUT_STUDIO_LINK,
	HEADOUT_SUPPORT_LINE,
	INFLUENCER_PROGRAM_LINK,
	JOIN_HEADOUT_LINK,
	TOP_PERFORMING_CITIES,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const breakpoint = 750;

const FooterHeading = styled(LH2)`
	position: relative;
	margin-bottom: 0.625rem;
	text-transform: uppercase;
	padding-bottom: 0.1875rem;

	&& > span {
		${getFontDetailsByLabel(TYPE_LABELS.MISC_OVERLINE_LARGE)}
		color: ${tokens.colors.GRAY.G2};
	}
`;
const FooterBlock = styled.div`
	&& > a {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)}
		color: ${tokens.colors.GRAY.G3};
		margin-bottom: 0.75rem;
		display: block;

		&:hover {
			color: ${tokens.colors.BRAND_COLORS.PURPS};
		}
	}

	@media (max-width: ${breakpoint}px) {
		margin-bottom: 2.875rem;
	}
`;

const FooterListItem = styled.li`
	display: flex;

	&& > a {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)}
		color: ${tokens.colors.GRAY.G3};
		margin-bottom: 0.75rem;
		display: flex;
		align-items: center;
		gap: 0.3125rem;
	}

	& path.helpIcon-path-fill {
		fill: ${tokens.colors.GRAY.G3};
		stroke: transparent;
	}

	&:hover {
		color: ${tokens.colors.BRAND_COLORS.PURPS};

		& path {
			stroke: ${tokens.colors.BRAND_COLORS.PURPS};
		}

		& path.helpIcon-path-fill {
			fill: ${tokens.colors.BRAND_COLORS.PURPS};
			stroke: transparent;
		}
	}

	&&:last-of-type svg {
		margin-top: 3px;
	}
`;

const FooterBottom = styled.div<{
	isDarkBg: boolean;
}>`
	display: flex;
	width: 100%;
	padding: 0.9375rem;

	@media (min-width: ${breakpoint}px) {
		border-top: ${({ isDarkBg }) =>
			isDarkBg ? 'none' : `1px solid ${tokens.colors.GRAY.G6}`};
		grid-column: 1 / span all;
		max-width: 78rem;
		display: flex;
		justify-content: space-between;
		margin-top: 0rem;

		.social-media-links {
			svg {
				margin-right: 0;
				margin-left: 1rem;
			}
		}
	}

	@media (max-width: ${breakpoint}px) {
		grid-area: bottom;
		padding: 0 1.5rem 3.75rem;
		display: flex;
		flex-direction: column-reverse;
	}
`;

const FooterBottomLinks = styled.div<{
	isDarkBg: boolean;
}>`
	display: flex;
	&& > a,
	&& > span {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)}
		color: ${({ isDarkBg }) =>
			isDarkBg ? tokens.colors.GRAY.G8 : tokens.colors.GRAY.G2};
	}

	&& > a {
		&:hover {
			color: ${tokens.colors.BRAND_COLORS.PURPS};
		}
	}

	@media (min-width: ${breakpoint}px) {
		display: flex;
		grid-auto-flow: row;
		column-gap: 1rem;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

	@media (max-width: ${breakpoint}px) {
		border-top: 0.0394rem solid ${tokens.colors.GRAY.G6};
		padding-top: 1.25rem;
		margin-top: 1.25rem;
		display: flex;
		flex-wrap: wrap;

		.dot-1 {
			display: none;
		}

		a,
		span {
			margin-right: 0.625rem;
			margin-bottom: 0.625rem;
		}
	}
`;

const FooterWrapper = styled.footer<{
	isDarkBg: boolean;
}>`
	border-top: ${({ isDarkBg }) =>
		`1px solid ${
			isDarkBg ? tokens.colors.GRAY.G5 : tokens.colors.GRAY.G6
		}`};
	background: ${({ isDarkBg }) =>
		isDarkBg ? colors.DARK_PURPLE : 'transparent'};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	justify-items: flex-start;
	align-items: center;
`;

const StoreLinks = styled.div`
	display: flex;
	padding: 8px 16px 8px 8px;
	align-items: center;
	gap: 0.875rem;
	border-radius: 12px;
	border: 1px solid ${tokens.colors.GRAY.G6};
	background: ${tokens.colors.GRAY.G8};
	span:not(.block) {
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)}
	}
`;

const FooterHeader = styled.div`
	display: grid;
	margin: auto;
	width: 100%;

	@media (min-width: ${breakpoint}px) {
		max-width: 78rem;
		justify-items: flex-start;
		padding: 4rem 1.5rem 2.5rem;
	}

	@media (max-width: ${breakpoint}px) {
		padding: 2.875rem 1.5rem;
		justify-items: flex-start;
	}

	&& > logo {
		align-self: start;
		justify-self: start;
	}
`;

const FooterContent = styled.div<{ $isCollectionPage?: boolean }>`
	display: grid;
	margin: auto;
	grid-auto-rows: auto;
	padding: 0 ${({ $isCollectionPage }) => ($isCollectionPage ? 1 : 1.5)}rem;
	width: 100%;

	a {
		text-decoration: none;
		color: ${tokens.colors.GRAY.G3};
		transition: color 0.2s ease;
		&:hover {
			color: ${tokens.colors.BRAND_COLORS.PURPS};
		}
	}

	@media (min-width: ${breakpoint}px) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto 2.75rem;
		grid-template-areas:
			'help-1 cities-2 company-3 partner-4'
			'downloads-5 hide-6 hide-7 payments-8';
		column-gap: 5rem;
		max-width: 78rem;
		justify-items: flex-start;
	}
	@media (max-width: ${breakpoint}px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto auto auto auto;
		grid-template-areas:
			'help-1 help-1'
			'cities-2 company-3'
			'partner-4 partner-4'
			'payments-8 payments-8';
		column-gap: 1.5625rem;
	}

	.help-1 {
		grid-area: help-1;
	}
	.downloads-5 {
		grid-area: downloads-5;
		@media (min-width: ${breakpoint}px) {
			transform: translateY(-7.125rem);
			width: 240px;
		}
		@media (max-width: ${breakpoint}px) {
			transform: translateY(0rem);
			display: none;
		}
	}
	.cities-2 {
		grid-area: cities-2;
	}
	.company-3 {
		grid-area: company-3;
	}
	.partner-4 {
		grid-area: partner-4;
	}
	.payments-8 {
		grid-area: payments-8;
		@media (min-width: ${breakpoint}px) {
			transform: translateY(-7.5rem);
		}
		@media (max-width: ${breakpoint}px) {
			transform: translateY(0rem);
		}
	}
`;

type FooterElementsProps = {
	city?: any;
	host?: string;
	currentPage?: string;
	landingUrl?: string;
	currentCityCode?: string;
	languageCode?: string;
	logo?: any;
	showFooter?: boolean;
	showCitiesList?: boolean;
	discoverableCitiesCount?: number;
};

type FooterLink = {
	href: string;
	label: string;
	prefetch?: boolean;
	rel?: string;
	as?: string;
	icon?: JSX.Element;
	onClick?: (...args: any) => void;
};

const FooterElements = (props: FooterElementsProps) => {
	const {
		currentCityCode,
		landingUrl,
		city: { citiesMap },
		host,
		languageCode,
		showCitiesList,
		discoverableCitiesCount,
	} = props;
	const { query, pathname, asPath } = useRouter();
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	const isBookingFlow = doesCurrentRouteIncludeString('/book/', location);
	const isEnglish = languageCode === 'en';
	const isAppTicketsPage = doesCurrentRouteIncludeString('/apps', location);
	const hideFooterLists = !isAppTicketsPage;

	const getShowMoreText = () => {
		return strings.formatString(
			strings.PLUS_MORE,
			discoverableCitiesCount! - 7,
		);
	};

	const shouldHideFooter = () => {
		const { host, showFooter } = props;
		const isMobile = !PlatformUtils.isDesktop();
		const isMobileProfilePage =
			isMobile && doesCurrentRouteIncludeString('profile', location);
		const isMobileBookingPage = isMobile && isBookingFlow;
		const isTourSelectionPage =
			doesCurrentRouteIncludeString('svg-tours', location) ||
			doesCurrentRouteIncludeString('seatmap-tours', location);
		const isMobileSearchPage =
			!PlatformUtils.isDesktop() &&
			doesCurrentRouteIncludeString('/search/', location);
		const isInWebviewMode =
			getSourceFromUrl(location) === 'ios_app' ||
			getSourceFromUrl(location) === 'android_app' ||
			getSourceFromUrl(location) === 'app';

		const isSupplyPartnerPage = doesCurrentRouteIncludeString(
			'supply-partner',
			location,
		);
		const isBiLinkGeneratorPage = doesCurrentRouteIncludeString(
			'bilink-generator',
			location,
		);
		// CHECK ITS USAGE
		const isConfirmationPage = doesCurrentRouteIncludeString(
			'confirmation',
			location,
		);

		if (isConfirmationPage) {
			return false;
		}

		return (
			!showFooter ||
			// @ts-expect-error TS(2532): Object is possibly 'undefined'.
			host.startsWith('book.') ||
			isMobileBookingPage ||
			isMobileProfilePage ||
			isTourSelectionPage ||
			isInWebviewMode ||
			isSupplyPartnerPage ||
			isBiLinkGeneratorPage ||
			isMobileSearchPage
		);
	};

	if (shouldHideFooter()) return null;

	const contactLinks: FooterLink[] = [
		{
			label: strings.FT_HELP_CENTER,
			href: `${
				languageCode && languageCode !== 'en' ? `/${languageCode}` : ''
			}/help/`,
			rel: 'noreferrer noopener',
			icon: <HelpIcon width={FOOTER_CONTACT_ICON_DIM} />,
		},
		{
			label: strings.FT_CHAT,
			href: '',
			rel: 'noreferrer noopener',
			icon: <MessageIcon />,
			onClick: openZendeskChatWidget,
		},
		{
			label: strings.FT_CALL,
			href: `tel:${HEADOUT_SUPPORT_LINE}`,
			rel: 'noreferrer noopener',
			icon: <PhoneIcon />,
		},
		{
			label: strings.FT_SUPPORT_MAIL,
			href: 'mailto:support@headout.com',
			rel: 'noreferrer noopener',
			icon: <MailIcon />,
		},
	];

	const headoutLinks: FooterLink[] = [
		{
			label: strings.FT_OUR_STORY,
			href: '/about-us/',
			prefetch: false,
			rel: 'noreferrer noopener',
		},
		{
			label: strings.FT_CAREERS,
			href: '/careers/',
			prefetch: false,
		},
		{
			label: strings.FT_NEWSROOM,
			href: '/newsroom/',
			rel: 'noreferrer noopener',
		},
		{
			label: strings.FT_COMPANY_BLOG,
			href: HEADOUT_STUDIO_LINK,
			prefetch: false,
			rel: 'noreferrer noopener',
		},
		{
			label: strings.FT_TRAVEL_BLOG,
			href: BLOG_LINK,
			prefetch: false,
			rel: 'noreferrer noopener',
		},
	];

	const partnerLinks: FooterLink[] = [
		{
			label: strings.FT_EXP_PROVIDERS,
			href: JOIN_HEADOUT_LINK,
		},
		{
			label: strings.FT_AFFILIATES_PORTAL,
			href: AFFILIATES_PORTAL_LINK,
		},
		{
			label: strings.CREATORS_AND_INFLUENCERS,
			href: INFLUENCER_PROGRAM_LINK,
		},
	];

	return (
		<FooterWrapper id='Footer' isDarkBg={isAppTicketsPage}>
			<Conditional if={hideFooterLists}>
				<FooterHeader>
					{/* @ts-expect-error TS(2739): Type '{ clickUrl: string; whitewash: false; }' is ... Remove this comment to see the full error message */}
					<Logo
						clickUrl={
							getWhitelistedCategoryUrl({ host }) ||
							landingUrl ||
							'/'
						}
						whitewash={false}
					/>
				</FooterHeader>

				<FooterContent
					$isCollectionPage={isCollectionUrl(location.pathname)}
				>
					<FooterBlock className='help-1'>
						<FooterHeading>
							<LSpan>
								{strings.STICKY_FOOTER.HEADING.GETHELP}
							</LSpan>
						</FooterHeading>
						<ul>
							{contactLinks.map((link, index) => {
								return (
									<FooterListItem key={index}>
										{link?.onClick ? (
											<a onClick={link.onClick}>
												{link.icon}
												{link.label}
											</a>
										) : (
											<Link
												prefetch={false}
												href={link?.href}
												as={link?.as}
												rel='noreferrer noopener'
												target='_blank'
											>
												{link.icon}
												{link.label}
											</Link>
										)}
									</FooterListItem>
								);
							})}
						</ul>
					</FooterBlock>
					<FooterBlock className='downloads-5'>
						<StoreLinks>
							<Image
								src={DOWNLOAD_APP_QR}
								width={DOWNLOAD_APP_QR_DIM}
								height={DOWNLOAD_APP_QR_DIM}
								alt={strings.SCAN_CODES}
							/>{' '}
							<LSpan>{strings.CMN_DOWNLOAD_HEADOUT}</LSpan>
						</StoreLinks>
					</FooterBlock>

					<Conditional if={showCitiesList}>
						<FooterBlock className='cities-2'>
							<FooterHeading>
								<LSpan>
									{strings.STICKY_FOOTER.HEADING.CITIES}
								</LSpan>
							</FooterHeading>
							<ul>
								{TOP_PERFORMING_CITIES.map((item, index) => {
									const currentLang = languageCode
										? languageCode
												?.toUpperCase()
												?.replace(/-/g, '_')
										: 'EN';
									const url =
										citiesMap?.[item]?.urlSlugs?.[
											currentLang
										];

									const pagesHref = `/[lang]/cities/[city]?city=${citiesMap?.[
										item
									]?.cityCode.toLowerCase()}&lang=${currentLang.toLowerCase()}`;

									return (
										<FooterListItem key={index}>
											<Link
												href={pagesHref}
												as={url}
												target={'_blank'}
											>
												{citiesMap?.[item]?.displayName}
											</Link>
										</FooterListItem>
									);
								})}
								<FooterListItem>
									<Link
										as={`${
											!isEnglish ? `/${languageCode}` : ''
										}/cities`}
										href={`${
											!isEnglish ? `/[lang]` : ''
										}/cities`}
										target={'_blank'}
									>
										{getShowMoreText()}
									</Link>
								</FooterListItem>
							</ul>
						</FooterBlock>
					</Conditional>

					<FooterBlock className='company-3'>
						<FooterHeading>
							<LSpan>{strings.CMN_HEADOUT}</LSpan>
						</FooterHeading>
						<ul>
							{headoutLinks.map((link, index) => (
								<FooterListItem key={index}>
									<Link
										prefetch={false}
										href={link.href}
										as={link.as}
										rel='noreferrer noopener'
										target='_blank'
									>
										{link.label}
									</Link>
								</FooterListItem>
							))}
						</ul>
					</FooterBlock>

					<FooterBlock className='partner-4'>
						<FooterHeading>
							<LSpan>{strings.CMN_PARTNERS}</LSpan>
						</FooterHeading>
						<ul>
							{partnerLinks.map((link, index) => (
								<FooterListItem key={index}>
									<Link
										prefetch={false}
										href={link.href}
										as={link.as}
										rel='noreferrer noopener'
										target='_blank'
									>
										{link.label}
									</Link>
								</FooterListItem>
							))}
						</ul>
					</FooterBlock>
					<FooterBlock className='payments-8'>
						<FooterHeading>
							<LSpan>
								{
									strings.STICKY_FOOTER.HEADING
										.ACCEPTED_PAYMENTS
								}
							</LSpan>
						</FooterHeading>
						<AcceptedMethods />
					</FooterBlock>
				</FooterContent>
			</Conditional>
			<FooterBottom isDarkBg={isAppTicketsPage}>
				<FooterBottomLinks
					isDarkBg={isAppTicketsPage}
					className='FooterBottomLinks'
				>
					<Link
						prefetch={false}
						href={'/terms-of-use'}
						as={'/terms-of-use'}
						rel='noreferrer noopener'
						target='_blank'
					>
						{strings.FT_TERMS_USE}
					</Link>
					<span className='dot-2' aria-hidden='true'>
						•
					</span>
					<Link
						href={'/privacy-policy'}
						as={'/privacy-policy'}
						rel='noreferrer noopener'
						target='_blank'
					>
						{strings.FT_PRIVACY_POLICY}
					</Link>
					<span className='dot-1' aria-hidden='true'>
						•
					</span>
					<span>
						© Headout Inc, 82 Nassau St #60351 New York, NY 10038
					</span>
				</FooterBottomLinks>
				<SocialMedia
					isDarkBg={isAppTicketsPage}
					currentCityCode={currentCityCode}
				/>
			</FooterBottom>
		</FooterWrapper>
	);
};

const mapStateToProps = (state: any) => ({
	city: state?.city,
	host: getHost(state),
	landingUrl: getUserLandingUrl(state),
	cityCodes: getDiscoverableCityCodes(state),
	currentPage: getCurrentPage(state),
	currentCityCode: getCurrentCityCode(state),
	languageCode: getCurrentLanguageCode(state),
	logo: getDomainLogoConfig(state),
	showCitiesList: getDomainConfig(state)?.['showCitiesList'],
	showFooter: getDomainConfig(state)?.['showFooter'],
	discoverableCitiesCount: getDiscoverableCitiesCount(state),
});

const Footer = connect(mapStateToProps, null)(FooterElements);

export default Footer;
