import { ActionTypes } from 'Actions/actions';
import {
	CollectionByCategoryIdActionTypes,
	CollectionByCityCodeActionTypes,
	CollectionByIdActionTypes,
	CollectionByPersonaIdActionTypes,
	CollectionBySubcategoryIdActionTypes,
	CollectionCardByIdActionTypes,
} from 'ReduxTypes/collections';

export const requestCollectionById = (
	id: number,
): CollectionByIdActionTypes => ({
	type: ActionTypes.REQUEST_COLLECTIONS_DATA_BY_ID,
	collectionId: id,
});

export const receiveCollectionById = (
	id: number,
	data: object,
	url: string,
): CollectionByIdActionTypes => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_ID,
	collectionId: id,
	data,
	url,
});

export const requestCollectionByCityCode = (
	cityCode: string,
): CollectionByCityCodeActionTypes => ({
	type: ActionTypes.REQUEST_COLLECTIONS_DATA_BY_CITY_CODE,
	cityCode,
});

export const receiveCollectionByCityCode = (
	cityCode: string,
	data: object,
	url: string,
): CollectionByCityCodeActionTypes => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_CITY_CODE,
	cityCode,
	data,
	url,
});

export const receiveCollectionCardById = (
	id: number,
	data: object,
): CollectionCardByIdActionTypes => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_CARD_DATA_BY_ID,
	collectionId: id,
	data,
});

export const requestCollectionByCategoryId = (
	cityCode: string,
	categoryId: number,
): CollectionByCategoryIdActionTypes => ({
	type: ActionTypes.REQUEST_COLLECTIONS_DATA_BY_CATEGORY_ID,
	cityCode,
	categoryId,
});

export const receiveCollectionByCategoryId = (
	cityCode: string,
	categoryId: number,
	data: object,
): CollectionByCategoryIdActionTypes => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_CATEGORY_ID,
	cityCode,
	categoryId,
	data,
});

export const requestCollectionBySubcategoryId = (
	cityCode: string,
	subCategoryId: number,
): CollectionBySubcategoryIdActionTypes => ({
	type: ActionTypes.REQUEST_COLLECTIONS_DATA_BY_SUBCATEGORY_ID,
	cityCode,
	subCategoryId,
});

export const receiveCollectionBySubcategoryId = (
	cityCode: string,
	subCategoryId: number,
	data: object,
): CollectionBySubcategoryIdActionTypes => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_SUBCATEGORY_ID,
	cityCode,
	subCategoryId,
	data,
});

export const requestCollectionByPersonaId = (
	cityCode: string,
	personaAffinityId: number,
): CollectionByPersonaIdActionTypes => ({
	type: ActionTypes.REQUEST_COLLECTIONS_DATA_BY_PERSONA_ID,
	cityCode,
	personaAffinityId,
});

export const receiveCollectionByPersonaId = (
	cityCode: string,
	personaAffinityId: number,
	data: object,
): CollectionByPersonaIdActionTypes => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_PERSONA_ID,
	cityCode,
	personaAffinityId,
	data,
});

export const requestCollectionListByParams = (payload: { params: string }) => ({
	type: ActionTypes.REQUEST_COLLECTIONS_LIST,
	payload,
});

export const receiveCollectionListByParams = (payload: {
	params: string;
	response: any;
	receivedAt: number;
}) => ({
	type: ActionTypes.RECEIVE_COLLECTIONS_LIST,
	payload,
});

export const requestCollectionListByCity = ({
	cityCode,
	params,
}: {
	cityCode: string;
	params: string;
}) => ({
	payload: { cityCode, params },
	type: ActionTypes.REQUEST_COLLECTIONS_LIST_BY_CITY,
});
export const receiveCollectionListByCity = ({
	payload,
}: {
	payload: {
		cityCode: string;
		params: string;
		nextPage: boolean;
		receivedAt: number;
		isFetching: boolean;
		response: object;
	};
}) => ({
	payload,
	type: ActionTypes.RECEIVE_COLLECTIONS_LIST_BY_CITY,
});

export const requestCollectionsByCityIdSubCategoryId = (payload: {
	cityCode: string;
	subCategoryId: string;
	lang: string;
	limit: string;
}) => ({
	payload,
	type: ActionTypes.REQUEST_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID,
});

export const receiveCollectionsByCityIdSubCategoryId = (payload: {
	cityCode: string;
	subCategoryId: string;
	data: object;
}) => ({
	payload,
	type: ActionTypes.RECEIVE_COLLECTIONS_BY_CITY_ID_SUBCATEGORY_ID,
});
