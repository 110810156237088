import { DefaultRootState } from 'react-redux';

import { TProductCardDataV2 } from 'Components/mobile/miniProductCard/types';

import { combineUnique, customMergeDeep } from 'Utils/gen';

import { ActionTypes } from 'Actions/actions';

export interface State extends DefaultRootState {
	byId: object;
	byCardId: object;
	byCardIdV2: { [id: number]: TProductCardDataV2 };
	byIdInEnglish: object;
	status: {
		isFetching: {
			byId: object;
			byIds: object;
		};
	};
	productPageExperimentVariant: string | null;
	productFeatureFlags: { [id: string | number]: Record<string, boolean> };
}

export const initialState: State = {
	byId: {},
	byCardId: {},
	byCardIdV2: {},
	byIdInEnglish: {},
	status: { isFetching: { byId: {}, byIds: {} } },
	productPageExperimentVariant: null,
	productFeatureFlags: {},
};

const addProductCardsToState = (state: State, productCards: any) => {
	const productCardsById = productCards.reduce((acc: any, product: any) => {
		const { id } = product;
		const {
			microBrandsHighlight: _microBrandsHighlight,
			topReviews: _topReviews,
			inclusions: _inclusions,
			exclusions: _exclusions,
			...productData
		} = product;
		return {
			...acc,
			[id.toString()]: productData,
		};
	}, {});
	return {
		...state,
		byCardId: {
			...state.byCardId,
			...productCardsById,
		},
		status: {
			...state['status'],
			isFetching: {
				...state['status']['isFetching'],
				byIds: false,
			},
		},
	};
};

const addProductCardsToStateV2 = (
	state: State,
	productCards: TProductCardDataV2[],
) => {
	const productCardsById = productCards.reduce((acc: any, product: any) => {
		const { id } = product;
		return {
			...acc,
			[id.toString()]: product,
		};
	}, {});
	return {
		...state,
		byCardIdV2: {
			...state.byCardIdV2,
			...productCardsById,
		},
		status: {
			...state['status'],
			isFetching: {
				...state['status']['isFetching'],
				byCardIdV2: false,
			},
		},
	};
};

export const modifyProductJson = (productJson: any) => {
	const propertyTypes: Record<string, any> = {};
	const propertyValues: Record<string, any> = {};
	const { variants } = productJson;
	const additionalProperties = variants
		.flatMap((variant: any) => variant.tours)
		.flatMap((tour: any) => tour.additionalPropertiesV2);

	additionalProperties.forEach((prop: any) => {
		if (!prop) return;
		propertyTypes[prop.type] = prop?.localisedContent?.type;
		propertyValues[prop.value] = prop?.localisedContent?.value;
	});
	return { ...productJson, propertyTypes, propertyValues };
};

export const productStore = (state = initialState, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_PRODUCTS:
		case ActionTypes.REQUEST_TRANSLATED_PRODUCT_CONTENT: {
			return {
				...state,
				status: {
					...state['status'],

					isFetching: {
						...state['status']['isFetching'],
						byIds: true,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCTS: {
			const { productCards } = action;
			return addProductCardsToState(state, productCards);
		}
		case ActionTypes.RECEIVE_SIMILAR_PRODUCTS:
		case ActionTypes.RECEIVE_PRODUCT_LIST:
			const { productCards } = action;
			return addProductCardsToState(state, productCards);
		case ActionTypes.RECEIVE_PRODUCT_LIST_BY_CITY: {
			const { productCards } = action;
			return addProductCardsToStateV2(state, productCards);
		}
		case ActionTypes.REQUEST_PRODUCT: {
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byId: {
							...state.status.isFetching.byId,
							[String(action.id)]: true,
						},
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT: {
			const { productJson } = action;
			const { id } = productJson;
			const productMap = state?.byId;
			return {
				...state,
				byId: {
					...productMap,
					[String(id)]: {
						...modifyProductJson(productJson),
					},
				},
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byId: {
							...state.status.isFetching.byId,
							[String(id)]: false,
						},
					},
				},
			};
		}
		case ActionTypes.RECEIVE_BULK_PRODUCTS: {
			const { productsJson } = action;
			if (!productsJson) return;
			const modifiedProductJson = Object.values(productsJson)
				.map((product: any) => modifyProductJson(product))
				.reduce(
					(acc: any, product: any) => ({
						...(acc ?? {}),
						[product.id]: product,
					}),
					{},
				);
			return {
				...state,
				byId: { ...(state?.byId ?? {}), ...modifiedProductJson },
			};
		}
		case ActionTypes.SET_PRODUCT_FEATURE_FLAGS: {
			const { id, featureFlags } = action;
			return {
				...state,
				productFeatureFlags: {
					...state.productFeatureFlags,
					[String(id)]: featureFlags,
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT_CONTENT_TRANSLATED: {
			const { productJson } = action;
			const { id } = productJson;
			const productMap = state?.byIdInEnglish;
			return {
				...state,
				byIdInEnglish: { ...productMap, [String(id)]: productJson },
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byId: {
							...state.status.isFetching.byId,
							[String(id)]: false,
						},
					},
				},
			};
		}
		case ActionTypes.RESET_PRODUCT: {
			const { id } = action.payload;
			const product = {
				id: Number(id),
			};
			return {
				...state,

				byId: {
					...state['byId'],
					[String(id)]: product,
				},
			};
		}
		default:
			return state;
	}
};

export const similarProductStore = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_SIMILAR_PRODUCTS: {
			const { id } = action;
			return customMergeDeep(state, {
				...{},
				[id]: {
					productIdList: [],
					isFetching: true,
				},
			});
		}
		case ActionTypes.RECEIVE_SIMILAR_PRODUCTS: {
			const { id, productIdList } = action;
			const finalProductIdList = combineUnique(
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				state?.[id]?.productIdList,
				productIdList,
			);
			return {
				...state,
				[id]: {
					productIdList: finalProductIdList,
					isFetching: false,
				},
			};
		}
		default:
			return state;
	}
};
